import {Injectable} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { RecaptchaParameters, RecaptchaVerifier } from 'firebase/auth';


declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(private auth: Auth) {
  }

  private static get ref(): Window {
    return window;
  }

  async createRecaptcha(containerId: string, options: RecaptchaParameters): Promise<RecaptchaVerifier> {
    if (!WindowService.ref.recaptchaVerifier) {
      WindowService.ref.recaptchaVerifier = new RecaptchaVerifier(containerId, options, this.auth);
      await WindowService.ref.recaptchaVerifier.render();
    }
    return WindowService.ref.recaptchaVerifier;
  }
}
