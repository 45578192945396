<ion-list>
  <app-list-item-song
      *ngFor="let booking of bookings"
      [song]="booking.song"
      [user]="booking.user"
      [color]="isMine(booking) ? 'list-highlighted' : ''"
      lineType="full"
      routerLink="./{{booking.booking.id}}"
      routerDirection="forward">
  </app-list-item-song>
</ion-list>
