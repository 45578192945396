import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Path from '../firestore/path';
import {SongCategoryEntity, toEntityQuery} from '../firestore/entity';
import {map, shareReplay} from 'rxjs/operators';
import SongCategory from '../models/song-category';
import {SettingsService} from './settings.service';
import { collection, collectionSnapshots, Firestore, CollectionReference } from '@angular/fire/firestore';

/**
 * 機材に関する処理を提供します。
 */
@Injectable({providedIn: 'root'})
export class MachineService {

  // カテゴリはデータ量が少ないので全データをキャッシュする
  private readonly sharedCategories: Observable<SongCategory[]>;

  constructor(
    private firestore: Firestore,
    private settingsService: SettingsService
  ) {
    const settings = this.settingsService.requireCurrent();
    const collectionRef = collection(this.firestore, Path.songCategories(settings.machine)) as CollectionReference<SongCategoryEntity>;
    this.sharedCategories = collectionSnapshots(collectionRef)
      .pipe(
        map(it => it
          .map(c => new SongCategory(toEntityQuery(c)))
          .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
        ),
        shareReplay(1))
    ;
  }

  /**
   * 曲のカテゴリを取得します
   */
  getSongCategories(): Observable<SongCategory[]> {
    return this.sharedCategories;
  }

  /**
   * 単独のカテゴリ情報を取得します
   * @param categoryId カテゴリID
   */
  getSongCategory(categoryId: string): Observable<SongCategory> {
    return this.sharedCategories.pipe(
      map(it => {
        const category = it.find(v => v.id === categoryId);
        if (!category) {
          throw new Error('Invalid Category ID');
        }
        return category;
      })
    );
  }

}
