import {Component} from '@angular/core';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {debounceTime, filter, flatMap, map, shareReplay, tap} from 'rxjs/operators';
import {SongService} from '../../../services/song.service';
import Song from '../../../models/song';
import {UserService} from '../../../services/user.service';
import {BookingService} from '../../../services/booking.service';
import {LoadingController, ToastController} from '@ionic/angular';
import {SettingsService} from '../../../services/settings.service';
import {createStandardToast} from '../../../common';

const MAX_DIGIT = 4;
const SEARCH_DEBOUNCE_TIME = 200;

@Component({
  selector: 'app-new-booking-number',
  templateUrl: './new-booking-number.page.html',
  styleUrls: ['./new-booking-number.page.scss'],
})
export class NewBookingNumberPageComponent {

  /**
   * 入力されている番号
   */
  public inputNumber = new Subject<number>();
  public deleteNumber = new Subject<void>();
  private inputDigits: Observable<number[]>;
  private digits = new BehaviorSubject<number[]>([]);
  public inputDigit1000: Observable<string>;
  public inputDigit100: Observable<string>;
  public inputDigit10: Observable<string>;
  public inputDigit1: Observable<string>;
  public isEnableBackspace: Observable<boolean>;
  public isEnableBooking: Observable<boolean>;
  public song = new BehaviorSubject<Song | null>(null);

  constructor(
    private songService: SongService,
    private userService: UserService,
    private bookingService: BookingService,
    private settingsService: SettingsService,
    private loadingController: LoadingController,
    private toastController: ToastController) {

    const settings = this.settingsService.requireCurrent();
    this.inputDigits = this.inputNumber.pipe(
      map(it => [it, ...this.digits.value].slice(0, MAX_DIGIT)),
      shareReplay()
    );

    const sharedDigits = this.digits.pipe(shareReplay());
    const deleteDigits = this.deleteNumber.pipe(
      map(_ => this.digits.value.slice(1)),
      shareReplay()
    );

    merge(this.inputDigits, deleteDigits).subscribe(it => this.digits.next(it));

    this.inputDigit1000 = this.digits.pipe(map(it => it.length > 3 ? it[3].toString() : ''));
    this.inputDigit100 = this.digits.pipe(map(it => it.length > 2 ? it[2].toString() : ''));
    this.inputDigit10 = this.digits.pipe(map(it => it.length > 1 ? it[1].toString() : ''));
    this.inputDigit1 = this.digits.pipe(map(it => it.length > 0 ? it[0].toString() : ''));

    sharedDigits
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        tap(_ => this.song.next(null)), // 検索中は曲を非表示にする
        map(it => parseInt(it.map(digits => digits.toString()).reverse().join(''), 10)),
        filter(it => isFinite(it)), // 初期値が NaN になることがある
        flatMap(it => this.songService.getSongByNumber(it)),
        map(it => it.length > 0 ? it[0] : null)
      )
      .subscribe(it => this.song.next(it));

    this.isEnableBackspace = sharedDigits.pipe(map(it => it.length > 0));
    this.isEnableBooking = this.song
      .pipe(
        map(it => it != null && settings.isBookingActive)
      );
  }

  async send() {
    const loading = await this.loadingController.create({message: '予約中'});
    await loading.present();
    const message = await this.makeBooking();
    await loading.dismiss();
    const toast = await createStandardToast(this.toastController, message);
    await toast.present();
  }

  /**
   * 予約処理を行います。
   * @return 結果を表す文字列を返します
   */
  private async makeBooking(): Promise<string> {
    const song = this.song.value;
    if (!song) {
      throw new Error('Song should be set before making booking.');
    }
    return await this.bookingService.addBooking(song)
      .then(_ => '予約が完了しました')
      .catch(it => it.message);
  }

}
