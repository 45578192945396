<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>予約システム設定</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="settings">
  <ion-list>
    <ion-list-header>開催設定</ion-list-header>
    <ion-item>
      <ion-label>
        <h3>開催中のイベント ID</h3>
        <p>開催対象にするイベント ID です</p>
      </ion-label>
      <ion-note slot="end" *ngIf="settings as $settings">{{$settings.eventId}}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>
        <h3>イベント開催期間中</h3>
        <p>OFF にするとメンテナンス中になります</p>
      </ion-label>
      <div *ngIf="settings as $settings">
        <ion-toggle
            slot="end"
            [(ngModel)]="settings.isEventActive"
            [checked]="$settings.isEventActive"
            (ionChange)="onChange()"
        ></ion-toggle>
      </div>
    </ion-item>
    <ion-item>
      <ion-label>
        <h3>予約受付中</h3>
        <p>ON にすると予約可能 OFF にすると予約不可になります</p>
      </ion-label>
      <div *ngIf="settings as $settings">
        <ion-toggle
            slot="end"
            [(ngModel)]="settings.isBookingActive"
            [checked]="$settings.isBookingActive"
            (ionChange)="onChange()"
        ></ion-toggle>
      </div>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-list-header>イベント設定</ion-list-header>
    <ion-item>
      <ion-label>
        <h3>同時予約曲数の制限</h3>
        <p>一人が同時に予約リストに追加できる曲数を確認します。設定は直接 DB を編集する必要があります</p>
      </ion-label>
      <ion-note slot="end">{{settings.concurrentBookingCount}}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>
        <h3>使用する機材</h3>
        <p>使用する機材コードを確認します。設定は直接 DB を編集する必要があります</p>
      </ion-label>
      <ion-note slot="end">{{settings.machine}}</ion-note>
    </ion-item>
  </ion-list>
</ion-content>
