import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminBookingsPageRoutingModule } from './admin-bookings-routing.module';

import { AdminBookingsPageComponent } from './admin-bookings.page';
import {ListBookingHistoryModule} from '../../../components/public/list-booking-history/list-booking-history.module';
import {ListSongsPageModule} from '../../public/list-songs/list-songs.module';
import {AdminBookingItemComponent} from '../../../components/admin/admin-booking-item/admin-booking-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminBookingsPageRoutingModule,
    ListBookingHistoryModule,
    ListSongsPageModule,
  ],
  declarations: [AdminBookingsPageComponent, AdminBookingItemComponent]
})
export class AdminBookingsPageModule {}
