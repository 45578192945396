<ion-item button="true" lines={{lineType}} [detail]="detail" [color]="backgroundColor">
  <div class="booking-content">
    <div class="order">
      <ion-chip outline="true" color="primary">
        <ion-label color="primary">{{order}}</ion-label>
      </ion-chip>
    </div>
    <div class="status">
      <ion-icon [name]="booking?.statusIcon || ''"></ion-icon>
    </div>
    <div class="number">{{song?.songNumber || ''}}</div>
    <div class="title">
      <div class="title-kp urigul" [innerHTML]="titleKpDescription"></div>
      <div class="title-jp" [innerHTML]="titleJpDescription"></div>
      <div class="description">
        <span><strong>{{user?.displayName || '不明なユーザー'}}</strong> さんが {{booking?.displayCreatedAt || ''}} に予約</span>
        <span *ngIf="booking?.isPerformed"> {{booking?.displayPerformedAt || ''}} に演奏</span>
      </div>
    </div>
  </div>
</ion-item>
