import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ListSongsPageRoutingModule} from './list-songs-routing.module';

import {ListSongsPageComponent} from './list-songs.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {CenterNoticeComponent} from '../../../components/public/center-notice/center-notice.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListSongsPageRoutingModule,
    ListItemSongComponentModule
  ],
  exports: [
    CenterNoticeComponent
  ],
  declarations: [ListSongsPageComponent, CenterNoticeComponent]
})
export class ListSongsPageModule {
}
