import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AdminAuthGuard, AuthGuard} from './guards/auth.guard';
import {GuestGuard} from './guards/guest.guard';
import {MaintenanceGuard} from './guards/maintenance.guard';
import {AuthRedirectGuard} from './guards/auth-redirect.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/public/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthRedirectGuard, GuestGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin-root/admin-root.module').then(m => m.AdminRootPageModule),
    canActivate: [AuthRedirectGuard, AdminAuthGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/public/maintenance/maintenance.module').then(m => m.MaintenancePageModule),
    canActivate: [AuthRedirectGuard, AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./pages/public/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthRedirectGuard, AuthGuard, MaintenanceGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/public/not-found/not-found.module').then(m => m.NotFoundPageModule)
  },
  {
    path: 'login-with-phone-number',
    loadChildren: () => import('./pages/public/login-with-phone-number/login-with-phone-number.module')
      .then(m => m.LoginWithPhoneNumberPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
    preloadingStrategy: PreloadAllModules
}
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
