import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MyBookingHistoryPageRoutingModule } from './my-booking-history-routing.module';

import { MyBookingHistoryPageComponent } from './my-booking-history.page';
import {ListBookingHistoryModule} from '../../../components/public/list-booking-history/list-booking-history.module';
import {ListSongsPageModule} from '../list-songs/list-songs.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MyBookingHistoryPageRoutingModule,
    ListBookingHistoryModule,
    ListSongsPageModule
  ],
  declarations: [MyBookingHistoryPageComponent]
})
export class MyBookingHistoryPageModule {}
