export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBL4pJO_pUGOs7Ut2UuTF3Q-U6YXTN4ZYo',
    authDomain: 'booking.dprk-karaoke.club',
    databaseURL: 'https://dprk-karaoke3.firebaseio.com',
    projectId: 'dprk-karaoke3',
    storageBucket: 'dprk-karaoke3.appspot.com',
    messagingSenderId: '93931761765',
    appId: '1:93931761765:web:688ba849bb66097b06968c',
    measurementId: 'G-YZ9SET5EL5'
  },
};

