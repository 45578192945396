import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TabUserPageComponent} from './tab-user.page';

const routes: Routes = [
  {
    path: '',
    component: TabUserPageComponent
  },
  {
    path: 'history',
    loadChildren: () =>
      import('../my-booking-history/my-booking-history.module').then(m => m.MyBookingHistoryPageModule)
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('../edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabUserPageRoutingModule {
}
