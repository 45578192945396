<ion-header>
  <ion-toolbar color="primary">
    <ion-title>番号で予約</ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container>
    <div class="digits">
      <div class="digit">{{inputDigit1000 | async}}</div>
      <div class="digit">{{inputDigit100 | async}}</div>
      <div class="digit">{{inputDigit10 | async}}</div>
      <div class="digit">{{inputDigit1 | async}}</div>
    </div>
  </ng-container>
  <ion-card>
    <ion-card-header *ngIf="song | async as $song; else searching">
      <ion-card-title class="urigul">{{$song.titleKp}}</ion-card-title>
      <ion-card-subtitle>{{$song.titleJp}}</ion-card-subtitle>
    </ion-card-header>
  </ion-card>
  <ion-grid class="input-number">
    <ion-row>
      <ion-col *ngFor="let n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]" size-xs="3" size-sm="2" size-lg="1">
        <ion-button size="large" color="light" (click)="inputNumber.next(n)">{{n}}</ion-button>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-lg="1">
        <ion-button size="large" color="tertiary" (click)="deleteNumber.next()"
                    [disabled]="(isEnableBackspace | async) === false">
          <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-xs="3" size-sm="2" size-lg="1">
        <ion-button size="large" color="secondary" (click)="send()" [disabled]="(isEnableBooking | async) === false">
          <ion-icon name="checkmark"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>

<ng-template #searching>
  <ion-card-header>
    <ion-card-title class="urigul">노래를 선택합시다</ion-card-title>
    <ion-card-subtitle>曲を選択してください</ion-card-subtitle>
  </ion-card-header>
</ng-template>