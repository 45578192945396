import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ActionSheetController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {BookingService} from '../../../services/booking.service';
import {UserService} from '../../../services/user.service';
import {SettingsService} from '../../../services/settings.service';
import {map, shareReplay} from 'rxjs/operators';
import {appConfig} from '../../../common';

function getAuthProviderIconClass(authProvider: string): string {
  switch (authProvider.toLowerCase()) {
    case 'twitter':
      return 'logo-twitter';
    case 'facebook':
      return 'logo-facebook';
    case 'google':
      return 'logo-google';
    default:
      return 'help-circle';
  }
}

@Component({
  selector: 'app-tab-user',
  templateUrl: 'tab-user.page.html',
  styleUrls: ['tab-user.page.scss']
})
export class TabUserPageComponent {
  public displayName: Observable<string>;
  public note: Observable<string>;
  public authProvider: Observable<string>;
  public authProviderIconClass: Observable<string>;
  public isAdmin: Observable<boolean>;
  public readonly version: string;

  constructor(
    public settingsService: SettingsService,
    public bookingService: BookingService,
    public userService: UserService,
    public actionSheetController: ActionSheetController,
    public router: Router) {

    const myUser = this.userService.myUser().pipe(shareReplay());
    this.displayName = myUser.pipe(map(it => it.entity.displayName));
    this.note = myUser.pipe(map(it => it.entity.note || ''));
    this.authProvider = myUser.pipe(map(it => it.entity.method.toString()));
    this.authProviderIconClass = this.authProvider.pipe(map(it => getAuthProviderIconClass(it)));
    this.isAdmin = myUser.pipe(map(it => it.isAdmin));
    this.version = appConfig.version;
  }

  public async logout() {
    const sheet = await this.actionSheetController.create({
      header: 'ログアウトします。よろしいですか?',
      buttons: [{
        text: 'ログアウトする',
        role: 'destructive',
        handler: async () => {
          await this.userService.logout();
          return true;
        }
      }]
    });
    await sheet.present();
  }

}
