import {Component} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import User from '../../../models/user';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-user-detail',
  templateUrl: './admin-user-detail.page.html',
  styleUrls: ['./admin-user-detail.page.scss'],
})
export class AdminUserDetailPageComponent {

  public user: Observable<User>;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    const userId = this.route.snapshot.paramMap.get('userId');
    if (!userId) {
      throw new Error('Illegal State: uid not set.');
    }

    this.user = this.userService.getUser(userId);
  }

}
