import {Component} from '@angular/core';
import {newUserEntityForGuest, UserEntity} from '../../../firestore/entity';
import {UserService} from '../../../services/user.service';
import {ToastController} from '@ionic/angular';
import {Location} from '@angular/common';
import {createStandardToast} from '../../../common';

@Component({
  selector: 'app-admin-new-guest',
  templateUrl: './admin-new-guest.page.html',
  styleUrls: ['./admin-new-guest.page.scss'],
})
export class AdminNewGuestPageComponent {

  public user: UserEntity = newUserEntityForGuest();

  constructor(
    private userService: UserService,
    private location: Location,
    private toastController: ToastController
  ) {
  }

  async register(_: MouseEvent) {
    await this.userService.addUser(this.user);
    const toast = await this.makeToast('ゲストユーザーを作成しました');
    await toast.present();
    this.location.back();
  }

  private async makeToast(message: string): Promise<HTMLIonToastElement> {
    return await this.toastController.create({
      message, duration: 500
    });
  }
}
