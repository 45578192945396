import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotFoundPageRoutingModule } from './not-found-routing.module';

import { NotFoundPageComponent } from './not-found.page';
import {ListSongsPageModule} from '../list-songs/list-songs.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NotFoundPageRoutingModule,
    ListSongsPageModule
  ],
  declarations: [NotFoundPageComponent]
})
export class NotFoundPageModule {}
