import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {ListBookingHistoryComponent} from './list-booking-history.component';
import {ListItemSongComponentModule} from '../list-item-song/list-item-song.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ListItemSongComponentModule, RouterModule],
  declarations: [ListBookingHistoryComponent],
  exports: [ListBookingHistoryComponent]
})
export class ListBookingHistoryModule {
}
