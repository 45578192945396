import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BookingHistoryPageComponent } from './booking-history.page';

const routes: Routes = [
  {
    path: '',
    component: BookingHistoryPageComponent
  },
  {
    path: ':bookingId',
    loadChildren: () =>
      import('../booking-detail/booking-detail.module').then(m => m.BookingDetailPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BookingHistoryPageRoutingModule {}
