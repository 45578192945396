import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListSongsPageComponent } from './list-songs.page';

const routes: Routes = [
  {
    path: '',
    component: ListSongsPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListSongsPageRoutingModule {}
