import {Component, Input} from '@angular/core';
import {BookingView} from 'src/app/models/booking';
import User from '../../../models/user';

@Component({
  selector: 'app-list-booking-history',
  templateUrl: './list-booking-history.component.html',
  styleUrls: ['./list-booking-history.component.scss'],
})
export class ListBookingHistoryComponent {

  @Input() public bookings: BookingView[] = [];
  @Input() public myUser: User | null = null;

  constructor() {
  }

  isMine(booking: BookingView): boolean {
    return booking.user?.id === this.myUser?.id;
  }

}
