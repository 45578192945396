import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {BookingHistoryPageRoutingModule} from './booking-history-routing.module';

import {BookingHistoryPageComponent} from './booking-history.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {ListBookingHistoryModule} from '../../../components/public/list-booking-history/list-booking-history.module';
import {ListSongsPageModule} from '../list-songs/list-songs.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BookingHistoryPageRoutingModule,
    ListItemSongComponentModule,
    ListBookingHistoryModule,
    ListSongsPageModule
  ],
  declarations: [BookingHistoryPageComponent]
})
export class BookingHistoryPageModule {
}
