import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TabBookingPageComponent} from './tab-booking.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {TabBookingPageRoutingModule} from './tab-booking-routing.module';
import {ListSongsPageModule} from '../list-songs/list-songs.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild([{path: '', component: TabBookingPageComponent}]),
    ListItemSongComponentModule,
    TabBookingPageRoutingModule,
    ListSongsPageModule
  ],
  declarations: [TabBookingPageComponent]
})
export class TabBookingPageModule {
}
