import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Auth, getRedirectResult } from '@angular/fire/auth';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard  {

  constructor(
    private authService: Auth,
    private userService: UserService,
    private router: Router) {
  }

  async canActivate(
    _1: ActivatedRouteSnapshot,
    _2: RouterStateSnapshot): Promise<boolean> {
    const result = await getRedirectResult(this.authService);

    // リダイレクトからの戻り
    if (result?.user) {
      await this.userService.onLoginSuccess(result);
      await this.router.navigateByUrl('/');
      return false;
    } else {
      return true;
    }
  }

}
