import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Observable} from 'rxjs';
import User from '../../../models/user';
import {LoadingController} from '@ionic/angular';

@Component({
  selector: 'app-admin-attendee',
  templateUrl: './admin-attendee.page.html',
  styleUrls: ['./admin-attendee.page.scss'],
})
export class AdminAttendeePageComponent implements OnInit {

  public users: Observable<User[]>;

  constructor(
    private userService: UserService,
    private loadingController: LoadingController
  ) {
    this.users = this.userService.getAllUsers();
  }

  async ngOnInit(): Promise<void> {
    const loading = await this.loadingController.create({message: '読み込み中'});
    await loading.present();

    this.users.subscribe(async _ => loading.dismiss());
  }

}
