import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminAlternativeBookingPageRoutingModule } from './admin-alternative-booking-routing.module';

import { AdminAlternativeBookingPageComponent } from './admin-alternative-booking.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminAlternativeBookingPageRoutingModule
  ],
  declarations: [AdminAlternativeBookingPageComponent]
})
export class AdminAlternativeBookingPageModule {}
