<ion-header>
  <ion-toolbar color="primary">
    <ion-title>予約情報</ion-title>
  </ion-toolbar>
  <ion-segment (ionChange)="onFilterChange($event)" value="waiting">
    <ion-segment-button value="waiting">
      <ion-label>
        <ion-badge class="count" *ngIf="countWaiting | async as $c">{{$c}}</ion-badge>
        <span class="count-category">予約中</span>
      </ion-label>
    </ion-segment-button>
    <ion-segment-button value="performed">
      <ion-label>
        <ion-badge class="count" *ngIf="countPerformed | async as $c">{{$c}}</ion-badge>
        <span class="count-category">演奏済み</span>
      </ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-header>
<ion-content>
  <ion-list>
    <ng-container *ngIf="{w: displayedBookings | async, u: myUser | async} as $info; else loading">
      <ng-container *ngIf="$info.w as $w">
        <ng-container *ngIf="$info.u as $u">
          <ng-container *ngIf="$w.length > 0; else empty">
            <app-list-item-song
                *ngFor="let view of $w; let n = index; last as last"
                [order]="n + 1"
                [song]="view.song"
                [user]="view.user"
                [lineType]="last ? 'none' : 'full'"
                [color]="view.isMine($u) ? 'list-highlighted' : ''"
                routerLink="./bookings/{{view.booking.id}}"
                routerDirection="forward">
            </app-list-item-song>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-list>
</ion-content>

<ng-template #empty>
  <ion-item>
    アイテムがありません。
  </ion-item>
</ng-template>


<ng-template #loading class="spinner">
  <ion-item>
    <ion-spinner></ion-spinner>
  </ion-item>
</ng-template>

<ng-template #skeleton>
  <ion-skeleton-text animated="true"></ion-skeleton-text>
</ng-template>
