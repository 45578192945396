import {Component, Input, OnInit} from '@angular/core';
import Song from '../../../models/song';
import {SongService} from '../../../services/song.service';
import {Subject} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {PostBookingService} from '../../../services/post-booking.service';

@Component({
  selector: 'app-tab-booking',
  templateUrl: 'tab-booking.page.html',
  styleUrls: ['tab-booking.page.scss']
})
export class TabBookingPageComponent implements OnInit {
  /**
   * 検索文字列
   */
  public searchText = '';

  public subjectInputSearchText = new Subject<string>();
  @Input() public searchResult: Song[] = [];
  @Input() public isSearching = false;

  /**
   * 検索中かどうか
   */
  public get isSearchMode(): boolean {
    return this.searchText.length > 0;
  }

  constructor(
    private songService: SongService,
    private postBookingService: PostBookingService
  ) {
  }

  async ngOnInit() {
    await this.subjectInputSearchText
      .pipe(
        tap(_ => this.isSearching = true),
        switchMap(it => this.songService.searchSong(it)),
        tap(_ => this.isSearching = false)
      )
      .subscribe(it => this.searchResult = it);
  }

  /**
   * アイテムクリック時の処理を記述します
   * @param song 該当するアイテムのデータ
   */
  async onItemClick(song: Song) {
    await this.postBookingService.postBooking(song);
  }

}
