/**
 * Firestore のパスを表します
 */
export default class Path {

  static readonly machines = 'machines';
  static readonly events = 'events';
  static readonly users = 'users';
  static readonly settings = 'settings/event';

  static songs(machineId: string): string {
    return `${Path.machines}/${machineId}/songs`;
  }

  static songCategories(machineId: string): string {
    return `${Path.machines}/${machineId}/categories`;
  }

  static bookings(eventId: string): string {
    return `${Path.events}/${eventId}/bookings`;
  }

}
