import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminUserDetailPageRoutingModule } from './admin-user-detail-routing.module';

import { AdminUserDetailPageComponent } from './admin-user-detail.page';
import {ListSongsPageModule} from '../../public/list-songs/list-songs.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminUserDetailPageRoutingModule,
    ListSongsPageModule
  ],
  exports: [
    AdminUserDetailPageComponent
  ],
  declarations: [AdminUserDetailPageComponent]
})
export class AdminUserDetailPageModule {}
