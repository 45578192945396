import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {ListItemCategoryComponent} from './list-item-category.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [ListItemCategoryComponent],
  exports: [ListItemCategoryComponent]
})
export class ListItemCategoryModule {
}
