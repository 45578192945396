import {Component} from '@angular/core';
import {appConfig, AuthProviderType} from '../../../common';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {Observable} from 'rxjs';
import {SettingsService} from '../../../services/settings.service';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPageComponent  {

  public method: typeof AuthProviderType = AuthProviderType;
  public readonly version: string;
  public isEventActive: Observable<boolean>;

  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private alertController: AlertController,
    private router: Router
  ) {
    this.isEventActive = this.settingsService.observableEventActive;
    this.version = appConfig.version;
  }

  public async onLoginClick(method: AuthProviderType) {
    switch (method) {
      case AuthProviderType.Twitter:
      case AuthProviderType.Facebook:
      case AuthProviderType.Google:
        try {
          await this.userService.loginWithSocial(method);
          await this.router.navigate(['/']);
        } catch (error) {
          const alert = await this.alertController.create({
            header: 'ログインに失敗',
            message: `ログインできませんでした。やり直してください。原因が不明な場合は以下のエラーメッセージを管理者にご連絡ください。\n\n${error}`,
            buttons: [{text: 'OK'}]
          });
          await alert.present();
        }
        return;

      case AuthProviderType.PhoneNumber:
        await this.router.navigate(['login/phone']);
        break;

      case AuthProviderType.Guest:
        throw new Error('Guest cannot log in.');
    }
  }

}
