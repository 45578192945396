<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>代理予約</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" *ngIf="user">
  <p><strong>{{user.displayName}}</strong> さんの代理で予約します。</p>
  <form>
    <ion-list>
      <ion-list-header>曲検索</ion-list-header>
      <ion-item>
        <ion-input name="songNumber" required type="decimal" placeholder="曲番号" [(ngModel)]="searchNumber"></ion-input>
        <ion-button slot="end" type="button" [disabled]="!isValidSearchNumber" (click)="searchSongByNumber()">
          検索
        </ion-button>
      </ion-item>
    </ion-list>
  </form>
  <ion-content *ngIf="song">
    <ion-list>
      <ion-list-header>検索結果</ion-list-header>
      <ion-item>
        <ion-label>曲番号</ion-label>
        <ion-note slot="end">{{song.songNumber}}</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>曲名</ion-label>
        <ion-note slot="end">
          <span class="urigul">{{song.titleKp}}</span> ({{song.titleJp}})
        </ion-note>
      </ion-item>
    </ion-list>
    <div class="ion-text-end">
      <ion-button slot="end" type="submit" (click)="addBooking()">代理登録</ion-button>
    </div>
  </ion-content>
</ion-content>
