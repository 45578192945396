import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ListCategoriesPageRoutingModule} from './list-categories-routing.module';

import {ListCategoriesPageComponent} from './list-categories.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {ListItemCategoryModule} from '../../../components/public/list-item-category/list-item-category.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListItemSongComponentModule,
    ListCategoriesPageRoutingModule,
    ListItemCategoryModule
  ],
  declarations: [ListCategoriesPageComponent]
})
export class ListCategoriesPageModule {
}
