import {Component, Input} from '@angular/core';

/**
 * 画面中央に表示するアラート
 */
@Component({
  selector: 'app-center-notice',
  templateUrl: './center-notice.component.html',
  styleUrls: ['./center-notice.component.scss'],
})
export class CenterNoticeComponent  {

  @Input() text = '';

  constructor() { }

}
