<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>予約情報管理</ion-title>
  </ion-toolbar>
</ion-header>
<ion-segment (ionChange)="onFilterChange($event)" value="all">
  <ion-segment-button value="all">
    <ion-label>すべて</ion-label>
  </ion-segment-button>
  <ion-segment-button value="waiting">
    <ion-label>予約待ち</ion-label>
  </ion-segment-button>
  <ion-segment-button value="performed">
    <ion-label>演奏済み</ion-label>
  </ion-segment-button>
</ion-segment>
<ion-content>
  <ion-list *ngIf="displayedBookings | async as $bookings">
    <app-admin-booking-item
        *ngFor="let booking of $bookings; let n = index"
        [booking]="booking.booking"
        [song]="booking.song"
        [user]="booking.user"
        [detail]="false"
        [order]="(n + 1).toString()"
        (click)="onItemClick(booking)"
    ></app-admin-booking-item>
  </ion-list>
</ion-content>

<ng-template #empty>
  <app-center-notice *ngIf="(isLoading | async) === false"></app-center-notice>
</ng-template>

