import {EntityWithMeta, SongCategoryEntity} from '../firestore/entity';

export default class SongCategory {
  constructor(private data: EntityWithMeta<SongCategoryEntity>) {
  }

  get id(): string {
    return this.data.id;
  }

  get titleJp(): string {
    return this.data.entity.titleJp || '';
  }

  get titleKp(): string {
    return this.data.entity.titleKp || '';
  }
}
