import {Component, Input, OnInit} from '@angular/core';
import {SongService} from '../../../services/song.service';
import {ActivatedRoute} from '@angular/router';
import Song from '../../../models/song';
import {MachineService} from '../../../services/machine.service';
import {LoadingController} from '@ionic/angular';
import {PostBookingService} from '../../../services/post-booking.service';
import {SettingsService} from '../../../services/settings.service';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-list-songs',
  templateUrl: './list-songs.page.html',
  styleUrls: ['./list-songs.page.scss'],
})
export class ListSongsPageComponent implements OnInit {

  private categoryId: number;

  @Input() public songs: Song[] = [];
  @Input() public title = '';
  public isBookingEnabled!: Observable<boolean>;

  constructor(
    private machineService: MachineService,
    private songService: SongService,
    private activatedRoute: ActivatedRoute,
    private loadingController: LoadingController,
    private postBookingService: PostBookingService,
    private settingsService: SettingsService
  ) {
    const categoryId = activatedRoute.snapshot.paramMap.get('categoryId');
    if (!categoryId) {
      throw new Error('CategoryId not set.');
    }
    this.categoryId = parseInt(categoryId, 10);
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({message: '曲リスト取得中'});
    await loading.present();

    this.isBookingEnabled = this.settingsService.observableBookingActive;

    this.machineService
      .getSongCategory(this.categoryId.toString())
      .subscribe(it => this.title = `${it.titleKp} (${it.titleJp})`);

    this.songService
      .getSongList(this.categoryId)
      .subscribe(it => {
        this.songs = it;
        loading.dismiss();
      });
  }

  /**
   * アイテムクリック時の処理を記述します
   * @param song 該当するアイテムのデータ
   */
  async onItemClick(song: Song) {
    // TODO: click イベントを子要素に渡すのが正しい対処方法
    if (this.settingsService.requireCurrent().isBookingActive) {
      await this.postBookingService.postBooking(song);
    }
    // TODO: 予約が終了している場合にエラーを表示する
  }

  get isEmpty(): boolean {
    return this.songs !== undefined && this.songs.length === 0;
  }

}
