import {Component, OnInit} from '@angular/core';
import {MachineService} from '../../../services/machine.service';
import SongCategory from '../../../models/song-category';
import {LoadingController} from '@ionic/angular';
import {SettingsService} from '../../../services/settings.service';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.page.html',
  styleUrls: ['./list-categories.page.scss'],
})
export class ListCategoriesPageComponent implements OnInit {

  public categories: SongCategory[] = [];

  constructor(
    private settingsService: SettingsService,
    private machineService: MachineService,
    private loadingController: LoadingController
  ) {
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({message: '取得中'});
    await loading.present();

    this.machineService
      .getSongCategories()
      .subscribe(it => {
        this.categories = it;
        loading.dismiss();
      });
  }

}
