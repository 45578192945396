import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { appConfig } from './app/common';

function getEnvironmentDescription(): string {
  if (window.location.href.search('localhost') >= 0) {
    return 'development';
  } else if (environment.production) {
    return 'production';
  } else {
    return 'staging';
  }
}

const environmentDescription = getEnvironmentDescription();
const sentrySettings: Sentry.BrowserOptions = {
  dsn: 'https://47c4a4a537db49b88f95a16dff739170@o1298558.ingest.sentry.io/6529696',
  environment: environmentDescription,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
};

if (environmentDescription === 'development') {
  sentrySettings.release = 'dprk-karaoke-booking@' + appConfig.version + '-dev';
}

Sentry.init(sentrySettings);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
