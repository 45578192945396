import {Component, Input} from '@angular/core';
import Song from '../../../models/song';
import {emphasizeImportantName} from '../../../common';
import Booking, {BookingStatus} from '../../../models/booking';
import User from '../../../models/user';

@Component({
  selector: 'app-admin-booking-item',
  templateUrl: './admin-booking-item.component.html',
  styleUrls: ['./admin-booking-item.component.scss'],
})
export class AdminBookingItemComponent  {

  @Input() public booking?: Booking;
  @Input() public song?: Song;
  @Input() public user?: User;
  @Input() public lineType?: string;
  @Input() public detail = true;
  @Input() public order?: string;

  constructor() {
  }

  get titleKpDescription(): string {
    return emphasizeImportantName(this.song?.titleKp || '');
  }

  get titleJpDescription(): string {
    return emphasizeImportantName(this.song?.titleJp || '');
  }

  get backgroundColor(): string {
    switch (this.booking?.status) {
      case BookingStatus.WAITING:
      case BookingStatus.PERFORMED:
        return '';
      case BookingStatus.CANCELLED:
        return 'light';
      default:
        return '';
    }
  }

}
