<script src="../tab-home/tab-home.page.ts"></script>
<ion-tabs>

  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="home">
      <ion-icon name="list-outline"></ion-icon>
      <ion-label>予約情報</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="booking">
      <ion-icon name="musical-notes"></ion-icon>
      <ion-label>新規予約</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="user">
      <ion-icon name="person"></ion-icon>
      <ion-label>マイページ</ion-label>
    </ion-tab-button>
  </ion-tab-bar>

</ion-tabs>
