import {Component, OnInit} from '@angular/core';
import User from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {BookingService} from '../../../services/booking.service';
import {ActivatedRoute} from '@angular/router';
import Song from '../../../models/song';
import {SongService} from '../../../services/song.service';
import {createStandardToast} from '../../../common';
import {take} from 'rxjs/operators';
import {LoadingController, ToastController} from '@ionic/angular';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-alternative-booking',
  templateUrl: './admin-alternative-booking.page.html',
  styleUrls: ['./admin-alternative-booking.page.scss'],
})
export class AdminAlternativeBookingPageComponent implements OnInit {

  public user!: User;
  public song: Song | null = null;
  public searchNumber = '';

  constructor(
    private userService: UserService,
    private songService: SongService,
    private bookingService: BookingService,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private loadingController: LoadingController
  ) {
  }

  async ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('userId');
    if (!userId) {
      throw new Error('User not determined.');
    }
    this.user = await firstValueFrom(this.userService.getUser(userId));
  }

  async searchSongByNumber() {
    if (!this.searchNumber) {
      throw new Error('searchNumber is not set.');
    }
    const songNumber = parseInt(this.searchNumber, 10);
    const song = await firstValueFrom(this.songService.getSongByNumber(songNumber));
    this.song = song.length > 0 ? song[0] : null;
  }

  async addBooking() {
    if (this.song == null) {
      throw new Error('Illegal state: song should not be null');
    }
    const loading = await this.loadingController.create({message: '代理予約中'});
    await loading.present();
    try {
      await this.bookingService.addAlternativeBooking(this.user, this.song);
      const toast = await createStandardToast(this.toastController, '代理登録が完了しました');
      await toast.present();
    } catch (e) {
      if (e instanceof Error) {
        const toast = await createStandardToast(this.toastController, e.message);
        await toast.present();
      } else {
        throw e;
      }
    }
    await loading.dismiss();
  }

  public get isValidSearchNumber(): boolean {
    return parseInt(this.searchNumber, 10) > 0;
  }

}
