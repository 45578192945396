import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TabHomePageComponent} from './tab-home.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {TabHomePageRoutingModule} from './tab-home-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ListItemSongComponentModule,
    TabHomePageRoutingModule,
    RouterModule.forChild([{path: '', component: TabHomePageComponent}])
  ],
  declarations: [TabHomePageComponent]
})
export class TabHomeModule {
}
