import { DocumentReference } from 'firebase/firestore';
import {EntityWithMeta, SongEntity} from '../firestore/entity';

/**
 * 曲情報とその処理について扱います
 */
export default class Song {
  constructor(private data: EntityWithMeta<SongEntity>) {
  }

  get ref(): DocumentReference<SongEntity> {
    return this.data.ref;
  }

  get id(): string {
    return this.data.id;
  }

  get songNumber(): number {
    return this.data.entity.songNumber;
  }

  get titleKp(): string {
    return this.data.entity.titleKp || '';
  }

  get titleJp(): string {
    return this.data.entity.titleJp || '';
  }
}
