import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TabBookingPageComponent} from './tab-booking.page';

const routes: Routes = [
  {
    path: 'songs',
    loadChildren: () =>
      import('../list-categories/list-categories.module').then(m => m.ListCategoriesPageModule)
  },
  {
    path: 'new',
    loadChildren: () =>
      import('../new-booking-number/new-booking-number.module').then(m => m.NewBookingNumberPageModule)
  },
  {
    path: 'history',
    loadChildren: () =>
      import('../booking-history/booking-history.module').then(m => m.BookingHistoryPageModule)
  },
  {
    path: '',
    component: TabBookingPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabBookingPageRoutingModule {
}
