import {AuthProviderType, RoleType} from '../common';
import Song from '../models/song';
import User from '../models/user';
import { v4 as uuid4 } from "uuid";
import { DocumentReference, DocumentSnapshot, QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';

// tslint:disable-next-line:no-empty-interface
export interface Entity {
}

export interface EntityWithMeta<E extends Entity> {
  entity: E;
  id: string;
  ref: DocumentReference<E>;
}

export function toEntityAngularFirestore<T extends Entity>(snapshot: DocumentSnapshot<T>): EntityWithMeta<T> {
  const entity = snapshot.data();
  if (!entity) {
    throw new Error('Entity not exist.');
  }
  return {entity: entity as T, id: snapshot.id, ref: snapshot.ref as DocumentReference<T>};
}

export function toEntity<T extends Entity>(snapshot: DocumentSnapshot<T>): EntityWithMeta<T> {
  const entity = snapshot.data();
  if (!entity) {
    throw new Error('Entity not exist.');
  }
  return {entity: entity as T, id: snapshot.id, ref: snapshot.ref as DocumentReference<T>};
}

export function toEntityQuery<T extends Entity>(snapshot: QueryDocumentSnapshot<T>): EntityWithMeta<T> {
  return {entity: snapshot.data(), id: snapshot.id, ref: snapshot.ref as DocumentReference<T>};
}

export interface EventEntity extends Entity {
  title: string;
  startAt: Timestamp;
  endAt: Timestamp;
}

/**
 * 1人のユーザーを表します
 */
export interface UserEntity extends Entity {
  uid: string;
  displayName: string;
  method: AuthProviderType;
  role: RoleType;
  photoURL: string | null;
  note: string | null;
}

export function newUserEntityForGuest(): UserEntity {
  return {
    uid: uuid4(),
    displayName: '',
    method: AuthProviderType.Guest,
    role: RoleType.User,
    photoURL: null,
    note: null
  };
}

/**
 * 1件の予約を表します
 */
export interface BookingEntity extends Entity {
  song: DocumentReference<SongEntity>;
  user: DocumentReference<UserEntity>;
  createdAt: Timestamp;
  performedAt: Timestamp | null;
  cancelledAt: Timestamp | null;

  // 記録用 (サービスとしては使用禁止)
  songId: string;
  songNumber: number;
  titleKp?: string;
  titleJp?: string;
  userDisplayName: string;
}

export function createBookingEntity(song: Song, user: User): BookingEntity {
  const {songNumber, titleKp, titleJp} = song;
  return {
    song: song.ref,
    user: user.ref,

    // where でクエリするために null を埋めておく必要がある
    createdAt: Timestamp.now(),
    performedAt: null,
    cancelledAt: null,

    songId: song.id,
    songNumber, titleKp, titleJp,
    userDisplayName: user.entity.displayName,
  };
}

/**
 * 機材を表します
 */
export interface MachineEntity extends Entity {
  name: string;
  description: string;
}

/**
 * 曲のカテゴリを表します
 */
export interface SongCategoryEntity extends Entity {
  titleKp: string;
  titleJp?: string;
}

export interface SongEntity extends Entity {
  songNumber: number;
  titleKp?: string;
  titleJp?: string;
}

export function newSongEntity(songNumber: number): SongEntity {
  return {songNumber};
}

export interface BookingSettingsEntity extends Entity {
  eventId: string;
  machine: string;
  isEventActive: boolean;
  isBookingActive: boolean;
  concurrentBookingCount: number;
}
