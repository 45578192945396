<ion-header>
  <ion-toolbar color="primary">
    <ion-title>ゲスト (代理登録) 追加</ion-title>
  </ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button text=""></ion-back-button>
  </ion-buttons>
</ion-header>

<ion-content class="ion-padding">
  <p>
    ゲストを登録すると、代理予約できるようになります。
  </p>
  <form>
    <ion-list lines="full" class="ion-no-margin ion-no-padding">
      <ion-item>
        <ion-label position="stacked">ニックネーム
          <ion-text color="danger">*</ion-text>
        </ion-label>
        <ion-input required type="text" [(ngModel)]="user.displayName" name="name"></ion-input>
      </ion-item>
    </ion-list>
    <div class="ion-padding">
      <ion-button expand="block" class="ion-no-margin" (click)="register($event)" [disabled]="!user.displayName">
        ゲストを登録する
      </ion-button>
    </div>
  </form>

</ion-content>
