/**
 * 認証プロバイダの種類です
 */
import {ToastController} from '@ionic/angular';

import * as packageJson from '../../package.json';
import {combineLatest, Observable, ObservableInput, ObservedValueOf, of} from 'rxjs';

interface AppConfig {
  version: string;
}

export const appConfig: AppConfig = {
  version: packageJson.version
};

export enum AuthProviderType {
  Twitter = 'twitter',
  Facebook = 'facebook',
  Google = 'google',
  PhoneNumber = 'phone',
  Guest = 'guest'
}

export enum RoleType {
  User = 'user',
  Admin = 'admin'
}

export enum FilterType {
  ALL = 'all',
  WAITING = 'waiting',
  PERFORMED = 'performed'
}

/**
 * Toast の表示時間 (msecs)
 */
export const TOAST_DEFAULT_DURATION = 500;

const IMPORTANT_NAME = [
  '김일성',
  '김정일',
  '김정은',
  '김형직',
  '김정숙',
  '金日成',
  '金正日',
  '金正恩',
  '金亨稷',
  '金正淑'
];

export function emphasizeImportantName(text: string) {
  return IMPORTANT_NAME.reduce(
    (previous, key) => previous.replace(key, `<strong>${key}</strong>`),
    text
  );
}

export function toDisplayTime(date: Date | null | undefined): string {
  if (!date) {
    return '';
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);

  return `${month}/${day} ${hour}:${minute}`;
}

export async function createStandardToast(toastController: ToastController, message: string): Promise<HTMLIonToastElement> {
  return await toastController.create({message, duration: TOAST_DEFAULT_DURATION});
}

export function combineLatestAllowEmpty<O extends ObservableInput<any>>(sources: O[]): Observable<ObservedValueOf<O>[]> {
  return sources.length > 0 ? combineLatest(sources) : of([]);
}
