<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>管理者用ページ</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item routerLink="bookings" routerDirection="forward">
      <ion-icon name="musical-notes" slot="start"></ion-icon>
      <ion-label>
        <h3>予約情報管理</h3>
        <p>予約一覧表示・演奏済みへの変更・キャンセルなどの処理を行います</p>
      </ion-label>
    </ion-item>
    <ion-item routerLink="attendee" routerDirection="forward">
      <ion-icon name="people" slot="start"></ion-icon>
      <ion-label>
        <h3>参加者管理</h3>
        <p>参加者の一覧表示や管理を行います</p>
      </ion-label>
    </ion-item>
    <ion-item routerLink="system" routerDirection="forward">
      <ion-icon name="settings" slot="start"></ion-icon>
      <ion-label>
        <h3>予約システム設定</h3>
        <p>予約システムの状態 (メンテナンス中設定など) を行います</p>
      </ion-label>
    </ion-item>
    <ion-item routerLink="guest_new" routerDirection="forward">
      <ion-icon name="person-outline" slot="start"></ion-icon>
      <ion-label>
        <h3>ゲスト追加</h3>
        <p>スマートフォンを持っていない参加者をゲストとして代理登録します</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
