<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      新規予約
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-searchbar
      placeholder="曲名で検索 (前方一致・朝鮮語のみ)"
      [(ngModel)]="searchText"
      (ionChange)="subjectInputSearchText.next(searchText)">
  </ion-searchbar>
  <div *ngIf="isSearchMode; else menu">
    <div *ngIf="isSearching; else result">
      <div class="fullscreen">
        <ion-spinner></ion-spinner>
      </div>
    </div>
  </div>
  <ng-template #result>
    <div *ngIf="searchResult.length > 0; else empty">
      <ion-list>
        <app-list-item-song
            *ngFor="let song of searchResult"
            [song]="song"
            (click)="onItemClick(song)"
            [detail]="false">
        </app-list-item-song>
      </ion-list>
    </div>
  </ng-template>

  <ng-template #empty>
    <div class="fullscreen">
      <ion-icon name="warning"></ion-icon>
      <p>該当する曲がありません</p>
    </div>
  </ng-template>

  <ng-template #menu>
    <ion-list>
      <ion-list-header>
        <ion-label>予約方法</ion-label>
      </ion-list-header>

      <ion-item routerLink="./songs" routerDirection="forward">
        <ion-label>一覧から選択して予約</ion-label>
        <ion-icon name="list" slot="start"></ion-icon>
      </ion-item>

      <ion-item routerLink="./new" routerDirection="forward">
        <ion-label>番号を入力して予約</ion-label>
        <ion-icon name="calculator" slot="start"></ion-icon>
      </ion-item>

      <ion-item routerLink="./history" routerDirection="forward">
        <ion-label>履歴から予約</ion-label>
        <ion-icon name="time" slot="start"></ion-icon>
      </ion-item>
    </ion-list>
  </ng-template>
</ion-content>
