<ion-header>
  <ion-toolbar color="primary">
    <ion-title>一覧から選択</ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <app-list-item-category
        *ngFor="let category of categories"
        [jp]="category.titleJp"
        [kp]="category.titleKp"
        routerLink="categories/{{category.id}}"
        routerDirection="forward">
    </app-list-item-category>
  </ion-list>
</ion-content>
