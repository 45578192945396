import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {firstValueFrom, ObservableInput, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import User from '../models/user';
import {ToastController} from '@ionic/angular';

@Injectable({providedIn: 'root'})
abstract class AuthGuardBase  {
  constructor(
    private userService: UserService,
    private router: Router,
    private toastController: ToastController
  ) {
  }

  abstract get errorMessage(): string;

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const myUserSource = this.userService.myUser({ignoreOnNotLoggedIn: false})
      .pipe(catchError<User, ObservableInput<User | null>>(_ => of(null)));
    const myUser = await firstValueFrom(myUserSource);
    if (this.validate(myUser)) {
      return true;
    } else {
      await this.showToast();
      await this.navigateOnError(this.router);
      return false;
    }
  }

  abstract validate(user: User | null): boolean;

  abstract navigateOnError(router: Router): Promise<void>;

  private async showToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: this.errorMessage,
      duration: 500,
    });
    await toast.present();
  }
}

/**
 * ログインしていないユーザーを強制的にログインページに遷移させるための処理を記述します
 */
@Injectable({providedIn: 'root'})
export class AuthGuard extends AuthGuardBase {

  public get errorMessage(): string {
    return 'ログインしてください';
  }

  public validate(user: User | null): boolean {
    return user != null;
  }

  public async navigateOnError(router: Router) {
    await router.navigate(['/login']);
  }

}

@Injectable({providedIn: 'root'})
export class AdminAuthGuard extends AuthGuardBase {

  public get errorMessage(): string {
    return '権限がありません';
  }

  public validate(user: User | null): boolean {
    return !!(user?.isAdmin);
  }

  public async navigateOnError(router: Router): Promise<void> {
    await router.navigate(['/']);
  }
}
