<ion-header>
  <ion-toolbar color="primary">
    <ion-title>予約詳細情報</ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="song-info">
    <h1 class="number">
      <ng-container *ngIf="songNumber | async as $songNumber; else skeleton">{{$songNumber}}</ng-container>
    </h1>
    <div class="title-kp urigul">
      <ng-container *ngIf="titleKp | async as $titleKp; else skeleton">{{$titleKp}}</ng-container>
    </div>
    <div class="title-jp">
      <ng-container *ngIf="titleJp | async as $titleJp; else skeleton">{{$titleJp}}</ng-container>
    </div>
  </div>
  <ion-list>
    <ion-item-group>
      <ion-item-divider color="tertiary">予約状況</ion-item-divider>
      <ion-item>
        <ion-label>予約した人</ion-label>
        <ion-note slot="end">{{(userDisplayName | async) || '不明なメンバー'}}</ion-note>
      </ion-item>
      <ion-item>
        <ion-label>予約時刻</ion-label>
        <ion-note slot="end">{{createdAt | async }}</ion-note>
      </ion-item>
      <ion-item lines="none">
        <ion-label>ステータス</ion-label>
        <ion-note slot="end">
          <ion-icon [name]="statusIcon | async"></ion-icon>
          {{status | async}}
        </ion-note>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider color="tertiary">操作</ion-item-divider>
      <ion-item *ngIf="(isMine | async)" button (click)="cancelBooking()" detail="false">
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label>この予約をキャンセルする</ion-label>
      </ion-item>
      <ion-item *ngIf="isBookingEnabled | async" button (click)="addBooking($event)" detail="false">
        <ion-icon name="musical-notes" slot="start"></ion-icon>
        <ion-label>同じ曲で予約する</ion-label>
      </ion-item>
      <!-- Not Implemented -->
      <ion-item *ngIf="false">
        <ion-icon name="create" slot="start"></ion-icon>
        <ion-label>曲情報を編集する</ion-label>
      </ion-item>
    </ion-item-group>
  </ion-list>
</ion-content>

<ng-template #skeleton>
  <ion-skeleton-text animated="true"></ion-skeleton-text>
</ng-template>