import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {flatMap, map, take} from 'rxjs/operators';
import { DocumentReference, query, QueryConstraint, Timestamp } from 'firebase/firestore';
import {
  collection,
  CollectionReference,
  collectionSnapshots,
  doc,
  docSnapshots,
  Firestore,
  orderBy,
  Query,
  setDoc,
  updateDoc,
  where,
} from "@angular/fire/firestore";
import {
  BookingEntity,
  createBookingEntity,
  toEntityAngularFirestore,
  toEntityQuery
} from '../firestore/entity';
import Path from '../firestore/path';
import Booking, {BookingStatus} from '../models/booking';
import {v4 as uuid4} from 'uuid';
import Song from '../models/song';
import {SettingsService} from './settings.service';
import {UserService} from './user.service';
import User from '../models/user';

export interface GetBookingOptions {
  containsCancelled: boolean;
} 

const defaultGetBookingOption: GetBookingOptions = {
  containsCancelled: false
};

export interface AddBookingOptions {
  ignoreTermRestriction: boolean;
  ignoreConcurrencyRestriction: boolean;
}

const defaultAddBookingOptions: AddBookingOptions = {
  ignoreTermRestriction: false,
  ignoreConcurrencyRestriction: false,
};


@Injectable({providedIn: 'root'})
export class BookingService {

  constructor(
    private firestore: Firestore,
    private settingsService: SettingsService,
    private userService: UserService) {
  }

  private static getChangeStatusUpdatingValue(status: BookingStatus): Partial<BookingEntity> {
    const now = Timestamp.now();
    switch (status) {
      case BookingStatus.WAITING:
        return {cancelledAt: null, performedAt: null};
      case BookingStatus.PERFORMED:
        return {cancelledAt: null, performedAt: now};
      case BookingStatus.CANCELLED:
        return {cancelledAt: now};
      default:
        throw new Error(`Illegal State: $status`);
    }
  }

  private static buildQuery(option: GetBookingOptions): QueryConstraint[] {
    const q: QueryConstraint[] = [];
    if (!option.containsCancelled) {
      q.push(where('cancelledAt', '==', null));
    }
    q.push(orderBy('createdAt', 'desc'));
    return q;
  }

  /**
   * 予約一覧を取得します
   * 予約時刻の新しい順に並びます
   */
  getBookingList(option: GetBookingOptions = defaultGetBookingOption): Observable<Booking[]> {
    const q = BookingService.buildQuery(option);
    return this.getBookingListWithQuery(q);
  }

  getMyBookingList(): Observable<Booking[]> {
    return this.userService.myUser().pipe(flatMap(it => this.getBookingListForUser(it)));
  }

  getBookingListForUser(user: User): Observable<Booking[]> {
    const queryConstraints = [
      where('user', '==', user.ref),
      where('cancelledAt', '==', null),
      orderBy('createdAt', 'desc')
    ];
    return this.getBookingListWithQuery(queryConstraints);
  }

  private getBookingListWithQuery(queryConstraints: QueryConstraint[]): Observable<Booking[]> {
    const eventId = this.settingsService.requireCurrent().eventId;
    const collectionRef = collection(this.firestore, Path.bookings(eventId)) as CollectionReference<BookingEntity>;
    return collectionSnapshots(
      query(collectionRef, ...queryConstraints)
    ).pipe(
      map(it => it.map(item => new Booking(toEntityQuery(item))))
    );
  }

  /**
   * 単一の予約情報を取得します
   */
  getBooking(bookingId: string): Observable<Booking> {
    const eventId = this.settingsService.requireCurrent().eventId;
    const docRef = doc(this.firestore, Path.bookings(eventId), bookingId) as DocumentReference<BookingEntity>;
    return docSnapshots(docRef).pipe(
      map(it => new Booking(toEntityAngularFirestore(it)))
    );
  }

  /**
   * 予約を新規追加します
   * @return 新規の予約 ID
   */
  async addBooking(song: Song): Promise<string> {
    const myUser = await firstValueFrom(this.userService.myUser());
    return this.addBookingForUser(myUser, song, defaultAddBookingOptions);
  }

  /**
   * 代理予約を行います。
   * 管理者向けの機能のため、予約受付時間外を無視して追加できます。
   *
   * @param user 対象ユーザー
   * @param song 楽曲
   */
  async addAlternativeBooking(user: User, song: Song): Promise<string> {
    return await this.addBookingForUser(user, song, {
      ignoreTermRestriction: true,
      ignoreConcurrencyRestriction: true
    });
  }

  private async addBookingForUser(user: User, song: Song, options: AddBookingOptions): Promise<string> {
    const settings = this.settingsService.requireCurrent();

    if (!options.ignoreTermRestriction && !settings.isBookingActive) {
      throw new Error(`予約受付時間外のため、予約できません。`);
    }

    const currentBookings = await firstValueFrom(this.getBookingListForUser(user));
    const waitingCount = currentBookings.filter(v => v.status === BookingStatus.WAITING).length;
    if (!options.ignoreConcurrencyRestriction && waitingCount >= settings.concurrentBookingCount) {
      throw new Error(`同時に予約できるのは ${settings.concurrentBookingCount} 件までです。歌い終わってから予約してください。`);
    }

    const entity = createBookingEntity(song, user);
    const id = uuid4();
    const docRef = doc(this.firestore, Path.bookings(settings.eventId), id);
    return setDoc(docRef, entity).then(() => id);
  }

  /**
   * 予約をキャンセルします
   */
  async cancelBooking(bookingId: string): Promise<void> {
    const settings = this.settingsService.requireCurrent();
    const docRef = doc(this.firestore, Path.bookings(settings.eventId), bookingId);
    return await updateDoc(docRef, {cancelledAt: Timestamp.now()});
  }

  /**
   * 予約ステータスを更新します
   * @param booking 対象の予約
   * @param status ステータス
   */
  async updateStatus(booking: Booking, status: BookingStatus): Promise<void> {
    const settings = this.settingsService.requireCurrent();
    const docRef = doc(this.firestore, Path.bookings(settings.eventId), booking.id);
    return await updateDoc(docRef, BookingService.getChangeStatusUpdatingValue(status));
  }
}
