import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MyBookingHistoryPageComponent} from './my-booking-history.page';

const routes: Routes = [
  {
    path: '',
    component: MyBookingHistoryPageComponent
  },
  {
    path: ':bookingId',
    loadChildren: () =>
      import('../booking-detail/booking-detail.module').then(m => m.BookingDetailPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyBookingHistoryPageRoutingModule {
}
