import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.page.html',
  styleUrls: ['./maintenance.page.scss'],
})
export class MaintenancePageComponent {

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  public async logout() {
    await this.userService.logout();
  }
}
