import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TabHomePageComponent} from './tab-home.page';

const routes: Routes = [
  {
    path: 'bookings/:bookingId',
    loadChildren: () =>
      import('../booking-detail/booking-detail.module').then(m => m.BookingDetailPageModule)
  },
  {
    path: '',
    component: TabHomePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabHomePageRoutingModule {
}
