import {BookingEntity, EntityWithMeta, toEntity} from '../firestore/entity';
import {from, Observable, zip} from 'rxjs';
import Song from './song';
import { map } from 'rxjs/operators';
import User from './user';
import {FilterType, toDisplayTime} from '../common';
import { Timestamp } from 'firebase/firestore';
import { getDoc } from '@angular/fire/firestore';

export enum BookingStatus {
  WAITING = 'waiting',
  PERFORMED = 'performed',
  CANCELLED = 'cancelled'
}

function compareTimestamp(a: Timestamp | null | undefined, b: Timestamp | null | undefined): number {
  const aM = a?.toMillis() || 0;
  const bM = b?.toMillis() || 0;
  return aM - bM;
}

/**
 * 予約データとその処理について扱います
 */
export default class Booking {
  constructor(private data: EntityWithMeta<BookingEntity>) {
  }

  static comparePerformedAt(lhs: Booking, rhs: Booking): number {
    return compareTimestamp(lhs.entity?.performedAt, rhs.entity.performedAt);
  }

  static compareCreatedAt(lhs: Booking, rhs: Booking): number {
    return compareTimestamp(lhs.entity?.createdAt, rhs.entity.createdAt);
  }

  get id(): string {
    return this.data.id;
  }

  private get entity(): BookingEntity {
    return this.data.entity;
  }

  get status(): BookingStatus {
    if (!!this.data.entity.cancelledAt) {
      return BookingStatus.CANCELLED;
    } else if (!!this.data.entity.performedAt) {
      return BookingStatus.PERFORMED;
    } else {
      return BookingStatus.WAITING;
    }
  }

  get statusIcon(): string {
    switch (this.status) {
      case BookingStatus.CANCELLED:
        return 'stop-circle';
      case BookingStatus.PERFORMED:
        return 'checkbox';
      case BookingStatus.WAITING:
        return 'musical-notes';
    }
    throw new Error(`Illegal State: invalid status ${this.status}`);
  }

  get statusDescription(): string {
    switch (this.status) {
      case BookingStatus.WAITING:
        return '予約中';
      case BookingStatus.PERFORMED:
        return '演奏済み';
      case BookingStatus.CANCELLED:
        return 'キャンセル';
    }
  }

  get displayCreatedAt(): string {
    return toDisplayTime(this.data.entity.createdAt.toDate());
  }

  get displayPerformedAt(): string {
    return toDisplayTime(this.data.entity.performedAt?.toDate());
  }

  get isPerformed(): boolean {
    return this.entity.performedAt != null;
  }

  getSong(): Observable<Song> {
    return from(getDoc(this.data.entity.song)).pipe(
      map(it => new Song(toEntity(it)))
    );
  }

  getUser(): Observable<User | undefined> {
    return from(getDoc(this.data.entity.user)).pipe(
      map(it => it.exists() ? new User(toEntity(it)) : undefined)
    );
  }
}

/**
 * 表示用のクラスです
 */
export class BookingView {
  constructor(public booking: Booking, public song: Song, public user?: User) {
  }

  static from = (booking: Booking): Observable<BookingView> => {
    return zip(booking.getSong(), booking.getUser()).pipe(map(it => new BookingView(booking, it[0], it[1])));
  }

  isMine(user: User): boolean {
    return this.user?.id === user.id;
  }

  isDisplayed(type: FilterType): boolean {
    switch (type) {
      case FilterType.ALL:
        return true;
      case FilterType.WAITING:
        return this.booking.status === BookingStatus.WAITING;
      case FilterType.PERFORMED:
        return this.booking.status === BookingStatus.PERFORMED;
    }
  }
}
