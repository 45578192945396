<ion-item [button]="isButton" [lines]="lineType" [detail]="detail" [color]="color">
  <div class="booking-content">
    <ion-chip *ngIf="order" outline="true" color="primary">
      <ion-label color="primary">{{order}}</ion-label>
    </ion-chip>
    <div class="number" *ngIf="!order">{{song?.songNumber || ''}}</div>
    <div class="title">
      <div *ngIf="order"><strong>{{song?.songNumber || ''}}</strong></div>
      <div class="title-kp urigul" [innerHTML]="titleKpDescription"></div>
      <div class="title-jp" [innerHTML]="titleJpDescription"></div>
      <div class="description" *ngIf="user">
        <div>
          <ion-icon name="person"></ion-icon>
          <strong>{{user?.displayName || '不明なユーザー'}}</strong>
        </div>
      </div>
    </div>
  </div>
</ion-item>
