import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {BookingSettingsEntity} from '../firestore/entity';
import Path from '../firestore/path';
import {distinctUntilChanged, filter, map, shareReplay, take} from 'rxjs/operators';
import { doc, docData, Firestore, DocumentReference, updateDoc } from '@angular/fire/firestore';
import { setDoc } from 'firebase/firestore';

@Injectable({ providedIn: "root" })
export class SettingsService {
  private readonly sharedSettings: Observable<BookingSettingsEntity>;
  public readonly settings = new BehaviorSubject<BookingSettingsEntity | null>(
    null
  );

  public readonly observableEventActive: Observable<boolean>;
  public readonly observableBookingActive: Observable<boolean>;

  /**
   * 現在の設定を取得します。
   * @throws Error 設定がまだ取得されていない場合は例外を投げます。
   */
  public requireCurrent(): BookingSettingsEntity {
    const settings = this.settings.value;
    if (!settings) {
      throw new Error("Settings have not been set yet.");
    }
    return settings;
  }

  /**
   * 設定を取得します。取得できるまで待機します。
   */
  public async fetch(): Promise<BookingSettingsEntity> {
    return await firstValueFrom(
      this.settings.pipe(
        filter(
          (it: BookingSettingsEntity | null): it is BookingSettingsEntity =>
            it !== null
        )
      )
    );
  }

  constructor(private firestore: Firestore) {
    // 予め Observable を作成しておき、複数の通信が走らないようにする
    this.sharedSettings = docData(
      doc(
        this.firestore,
        Path.settings
      ) as DocumentReference<BookingSettingsEntity>
    ).pipe(
      filter(
        (it: BookingSettingsEntity | undefined): it is BookingSettingsEntity =>
          !!it
      ),
      shareReplay(1)
    );

    this.sharedSettings.subscribe((it) => this.settings.next(it));
    this.observableEventActive = this.sharedSettings.pipe(
      map((it) => it.isEventActive),
      distinctUntilChanged()
    );
    this.observableBookingActive = this.sharedSettings.pipe(
      map((it) => it.isBookingActive),
      distinctUntilChanged()
    );
  }

  public async update(entity: BookingSettingsEntity): Promise<void> {
    return await setDoc(
      doc(
        this.firestore,
        Path.settings
      ) as DocumentReference<BookingSettingsEntity>,
      entity
    );
  }
}
