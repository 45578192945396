import {Injectable} from '@angular/core';
import Song from '../models/song';
import {ActionSheetController, AlertController, ToastController} from '@ionic/angular';
import {BookingService} from './booking.service';
import {createStandardToast} from '../common';

@Injectable({
  providedIn: 'root'
})
export class PostBookingService {

  constructor(
    private actionSheetController: ActionSheetController,
    private toastController: ToastController,
    private bookingService: BookingService,
    private alertController: AlertController
  ) {
  }

  /**
   * アイテムクリック時の処理を記述します
   * @param song 該当するアイテムのデータ
   */
  async postBooking(song: Song) {
    const description = `${song.songNumber}: ${song.titleKp} (${song.titleJp})`;
    const actionSheet = await this.actionSheetController.create({
      header: description,
      buttons: [{
        text: '予約する',
        icon: 'send',
        handler: async () => {
          const message = await this.bookingService.addBooking(song)
            .then(it => '予約が完了しました')
            .catch(it => it.message);
          await actionSheet.dismiss(message, 'ok');
          return true;
        }
      }]
    });

    actionSheet.onDidDismiss().then(async it => {
      const message = (it.role === 'backdrop') ? '予約を中断しました' : (it.data as string);
      const toast = await createStandardToast(this.toastController, message);
      await toast.present();
    });

    await actionSheet.present();
  }

  async cancelBooking(bookingId: string, onDone: () => void) {
    const alert = await this.alertController.create({
      header: 'キャンセル',
      message: '予約をキャンセルします。よろしいですか?',
      buttons: [
        {
          text: 'いいえ',
          role: 'cancel'
        },
        {
          text: 'はい',
          handler: async () => {
            await this.bookingService.cancelBooking(bookingId);
            const toast = await createStandardToast(this.toastController, '予約をキャンセルしました');
            await toast.present();

            onDone();
          }
        }
      ]
    });
    await alert.present();
  }
}
