import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SongEntity, toEntityQuery} from '../firestore/entity';
import {map} from 'rxjs/operators';
import Song from '../models/song';
import Path from '../firestore/path';
import {SettingsService} from './settings.service';
import {
  collection,
  collectionSnapshots,
  Firestore,
  query,
  QueryConstraint,
  Query,
  where,
  orderBy,
  startAt,
  endAt
 } from '@angular/fire/firestore';

/**
 * 曲情報取得サービスを提供します
 */
@Injectable({providedIn: 'root'})
export class SongService {

  constructor(
    private firestore: Firestore,
    private settingsService: SettingsService
  ) {
  }

  /**
   * 曲情報を取得します
   */
  getSongList(categoryId: number): Observable<Song[]> {
    // ソートしたキーでしか絞り込みできないので、機材の対象楽曲かはクライアント側でフィルタする
    return this.querySongs(where('category', '==', categoryId))
      .pipe(map(it => it.filter(song => song.songNumber > 0)));
  }

  /**
   * 曲を検索します
   * @param keyword 検索するキーワード
   */
  searchSong(keyword: string): Observable<Song[]> {
    // ソートしたキーでしか絞り込みできないので、機材の対象楽曲かはクライアント側でフィルタする
    return this.querySongs(
        orderBy('titleKp'),
        startAt(keyword),
        endAt(keyword + '\uf8ff')
      )
      .pipe(
        map(it => it.filter(song => song.songNumber > 0))
      );
  }

  /**
   * 曲番号から曲を検索します
   * @param songNumber 曲番号
   */
  getSongByNumber(songNumber: number): Observable<Song[]> {
    return this.querySongs(where('songNumber', '==', songNumber));
  }

  /**
   * クエリを適用して曲リストを取得します
   * @param queryFn 検索クエリ
   */
  private querySongs(...constraints: QueryConstraint[]): Observable<Song[]> {
    const machine = this.settingsService.requireCurrent().machine;
    const q = query(
      collection(this.firestore, Path.songs(machine)),
      ...constraints
    ) as Query<SongEntity>;
    return collectionSnapshots(q).pipe(
      map(it => it.map(item => new Song(toEntityQuery(item))))
    );
  }
}
