import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable, ObservableInput, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import User from '../models/user';

/**
 * 未ログインユーザーのみアクセスできるページをガードします (例: ログインページ)
 */
@Injectable({
  providedIn: 'root'
})
export class GuestGuard  {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.myUser({ignoreOnNotLoggedIn: false})
      .pipe(
        take(1),
        catchError<User, ObservableInput<User | null>>(_ => of(null)),
        map(user => {
          if (user !== null) {
            const _ = this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        })
      );
  }

}
