import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BookingService} from '../../../services/booking.service';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {BookingView} from '../../../models/booking';
import {flatMap, map, shareReplay, take} from 'rxjs/operators';
import {UserService} from '../../../services/user.service';
import {Location} from '@angular/common';
import {PostBookingService} from '../../../services/post-booking.service';
import {SongService} from '../../../services/song.service';
import {SettingsService} from '../../../services/settings.service';
import Song from '../../../models/song';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.page.html',
  styleUrls: ['./booking-detail.page.scss'],
})
export class BookingDetailPageComponent implements OnInit {

  public songNumber!: Observable<number>;
  public titleKp!: Observable<string>;
  public titleJp!: Observable<string>;
  public userDisplayName!: Observable<string | undefined>;
  public createdAt!: Observable<string>;
  public status!: Observable<string>;
  public statusIcon!: Observable<string>;
  public isMine!: Observable<boolean>;
  private readonly bookingId: string;
  private song = new BehaviorSubject<Song | null>(null);
  public isBookingEnabled!: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private songService: SongService,
    private bookingService: BookingService,
    private postBookingService: PostBookingService,
    private settingsService: SettingsService,
    private location: Location
  ) {
    const bookingId = this.route.snapshot.paramMap.get('bookingId');
    if (!bookingId) {
      throw new Error('bookingId not set.');
    }
    this.bookingId = bookingId;
  }

  async ngOnInit() {
    const myUser = await firstValueFrom(this.userService.myUser());
    const shareBookingView = this.bookingService
      .getBooking(this.bookingId)
      .pipe(flatMap(it => BookingView.from(it)), shareReplay());

    this.songNumber = shareBookingView.pipe(map(it => it.song.songNumber));
    this.titleKp = shareBookingView.pipe(map(it => it.song.titleKp));
    this.titleJp = shareBookingView.pipe(map(it => it.song.titleJp));
    this.userDisplayName = shareBookingView.pipe(map(it => it.user?.displayName));
    this.createdAt = shareBookingView.pipe(map(it => it.booking.displayCreatedAt));
    this.isMine = shareBookingView.pipe(map(it => it.user?.ref.id === myUser.ref.id));
    this.status = shareBookingView.pipe(map(it => it.booking.statusDescription));
    this.statusIcon = shareBookingView.pipe(map(it => it.booking.statusIcon));
    this.isBookingEnabled = this.settingsService.observableBookingActive;
    shareBookingView.subscribe(it => this.song.next(it.song));
  }

  async cancelBooking() {
    await this.postBookingService.cancelBooking(this.bookingId, () => this.location.back());
  }

  async addBooking(_: Event) {
    // AddBooking できるときには song は存在する
    const song = this.song.value;
    if (!song) {
      throw new Error('Song not set.');
    }
    await this.postBookingService.postBooking(song);
  }
}
