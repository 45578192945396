import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {BookingView} from '../../../models/booking';
import {BookingService} from '../../../services/booking.service';
import {flatMap, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {combineLatestAllowEmpty} from '../../../common';

@Component({
  selector: 'app-my-booking-history',
  templateUrl: './my-booking-history.page.html',
  styleUrls: ['./my-booking-history.page.scss'],
})
export class MyBookingHistoryPageComponent {

  public myBookings: Observable<BookingView[]>;

  constructor(
    private bookingService: BookingService,
  ) {
    this.myBookings = this.bookingService.getMyBookingList()
      .pipe(
        mergeMap(it => combineLatestAllowEmpty(it.map(item => BookingView.from(item)))),
        shareReplay()
      );
  }

}
