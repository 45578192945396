<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      プロフィール編集
    </ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="card">
    <div class="header">
      <div class="avatar">
        <ion-icon name="person-circle"></ion-icon>
      </div>
    </div>
  </div>

  <form>
    <ion-list lines="full" class="ion-no-margin ion-no-padding">
      <ion-item>
        <ion-label position="stacked">ニックネーム<ion-text color="danger">*</ion-text></ion-label>
        <ion-input required type="text" [(ngModel)]="displayName" name="name"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">自己紹介</ion-label>
        <ion-textarea rows="5" [(ngModel)]="note" name="note"></ion-textarea>
      </ion-item>
    </ion-list>
  </form>

  <div class="ion-padding">
    <ion-button expand="block" class="ion-no-margin" (click)="modify($event)" [disabled]="!isValid">更新する</ion-button>
  </div>
</ion-content>
