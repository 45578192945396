import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {BookingView} from '../../../models/booking';
import {BookingService} from '../../../services/booking.service';
import {LoadingController} from '@ionic/angular';
import {flatMap, shareReplay} from 'rxjs/operators';
import User from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {combineLatestAllowEmpty} from '../../../common';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.page.html',
  styleUrls: ['./booking-history.page.scss'],
})
export class BookingHistoryPageComponent implements OnInit {

  public bookings: Observable<BookingView[]>;
  public isLoading = new BehaviorSubject<boolean>(true);
  public loading!: HTMLIonLoadingElement;
  public myUser: Observable<User>;

  constructor(
    private bookingService: BookingService,
    private userService: UserService,
    private loadingController: LoadingController
  ) {
    this.bookings = this.bookingService.getBookingList()
      .pipe(
        flatMap(it => combineLatestAllowEmpty(it.map(item => BookingView.from(item)))),
        shareReplay()
      );
    this.myUser = this.userService.myUser();
    combineLatest([this.myUser, this.bookings]).subscribe(_ => this.isLoading.next(false));
  }

  public async ngOnInit(): Promise<void> {
    this.loading = await this.loadingController.create({message: '読み込み中'});
    this.isLoading.subscribe(async it => {
      if (it) {
        await this.loading.present();
      } else {
        await this.loading.dismiss();
      }
    });
  }
}
