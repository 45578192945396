<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>参加者情報</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="user | async as $user; else loading">
  <ion-list>
    <ion-list-header>ユーザー情報</ion-list-header>
    <ion-item>
      <ion-label>ユーザーID</ion-label>
      <ion-note slot="end">{{$user.id}}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>ユーザー名</ion-label>
      <ion-note slot="end">{{$user.displayName}}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>ユーザータイプ</ion-label>
      <ion-note slot="end">{{$user.type}}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>認証方式</ion-label>
      <ion-note slot="end">{{$user.authTypeName}}</ion-note>
    </ion-item>
  </ion-list>

  <ion-list>
    <ion-list-header>設定</ion-list-header>
    <ion-item>
      <ion-label>アクセス権限</ion-label>
      <ion-select [(ngModel)]="$user.role" [value]="$user.role" okText="OK" cancelText="キャンセル">
        <ion-select-option value="admin">管理者</ion-select-option>
        <ion-select-option value="user">一般</ion-select-option>
        <ion-select-option value="banned">アクセス禁止</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>

  <ion-list>
    <ion-list-header>処理</ion-list-header>
    <ion-item routerLink="./alternative" routerDirection="forward">
      <ion-label>代理予約</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ng-template #loading>
  <app-center-notice></app-center-notice>
</ng-template>