import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {WindowService} from '../../../services/window.service';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoadingController} from '@ionic/angular';
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';

@Component({
  selector: 'app-login-with-phone-number',
  templateUrl: './login-with-phone-number.page.html',
  styleUrls: ['./login-with-phone-number.page.scss'],
})
export class LoginWithPhoneNumberPageComponent implements OnInit {

  public phoneNumber = new BehaviorSubject('');
  public textColorSendCode = '';
  public messageSendCode  = '';
  public confirmCode = '';
  public isDisabledSendSms: Observable<boolean>;
  public canClickLogin: Observable<boolean>;
  private recaptcha!: RecaptchaVerifier;
  private confirmationResult = new BehaviorSubject<ConfirmationResult | null>(null);
  private isRecaptchaSuccess = new BehaviorSubject(false);

  constructor(
    private windowService: WindowService,
    private userService: UserService,
    private router: Router,
    private loadingController: LoadingController
  ) {
    this.canClickLogin = this.confirmationResult.pipe(map(it => !!it));
    this.isDisabledSendSms = combineLatest([this.isRecaptchaSuccess, this.phoneNumber])
      .pipe(
        map(it => !(it[0] && /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/.test(it[1]))),
      );
  }

  async ngOnInit() {
    this.recaptcha = await this.windowService.createRecaptcha('recaptcha', {
      callback: (_: any) => {
        this.isRecaptchaSuccess.next(true);
      },
      'expired-callback': (_: any) => {
        this.isRecaptchaSuccess.next(false);
      },
      'error-callback': (_: any) => {
        this.isRecaptchaSuccess.next(false);
      }
    });
  }

  async onSendCodeClick() {
    // 国際電話番号でなければ受け付けない
    const internationalPhoneNumber = this.phoneNumber.value?.replace(/^0/, '+81');
    if (internationalPhoneNumber.length < 10 + 1) {
      this.setSendCodeResult(false, '正しい電話番号を入力してください');
      return;
    }
    const loading = await this.loadingController.create();
    await loading.present();
    this.confirmationResult.next(await this.userService.loginWithPhoneNumber(internationalPhoneNumber, this.recaptcha));
    this.setSendCodeResult(true, 'SMSを送信しました');
    await loading.dismiss();
  }

  async onLoginClick() {
    const confirmationResult = this.confirmationResult.value;
    if (!confirmationResult) {
      throw new Error('Confirmation result should be set before logging in.');
    }
    const loading = await this.loadingController.create();
    await loading.present();
    await this.userService.confirmPhoneNumber(confirmationResult, this.confirmCode);

    // 電話番号認証した場合、初回はプロフィールが空なので、プロフィール編集ページに飛ばす
    const myUser = await firstValueFrom(this.userService.myUser());
    await this.router.navigate(myUser.displayName ? ['/'] : ['/tabs/user/profile']);
    await loading.dismiss();
  }

  private setSendCodeResult(isSuccess: boolean, message: string) {
    this.textColorSendCode = !isSuccess ? 'danger' : '';
    this.messageSendCode = message;
  }
}
