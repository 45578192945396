import {Component} from '@angular/core';
import {SettingsService} from '../../../services/settings.service';
import {BookingSettingsEntity} from '../../../firestore/entity';

@Component({
  selector: 'app-admin-system',
  templateUrl: './admin-system.page.html',
  styleUrls: ['./admin-system.page.scss'],
})
export class AdminSystemPageComponent {

  settings: BookingSettingsEntity;

  constructor(
    private settingsService: SettingsService,
  ) {
    this.settings = this.settingsService.requireCurrent();
  }

  /**
   * フィールドのデータが変更されたときに呼ばれます。
   */
  async onChange() {
    await this.settingsService.update(this.settings);
  }

}
