import {Component} from '@angular/core';
import {BookingService} from '../../../services/booking.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import Booking, {BookingStatus, BookingView} from '../../../models/booking';
import {filter, flatMap, map, shareReplay} from 'rxjs/operators';
import User from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {combineLatestAllowEmpty, FilterType} from '../../../common';
import {SettingsService} from '../../../services/settings.service';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-tab-home',
  templateUrl: 'tab-home.page.html',
  styleUrls: ['tab-home.page.scss']
})
export class TabHomePageComponent {

  constructor(
    private bookingService: BookingService,
    private userService: UserService,
    private settingsService: SettingsService,
    private alertController: AlertController
  ) {
    this.myUser = this.userService.myUser();
    this.bookings = this.bookingService.getBookingList()
      .pipe(
        flatMap(it => combineLatestAllowEmpty(it.map(item => BookingView.from(item)))),
        shareReplay()
      );

    this.displayedBookings = combineLatest([this.bookings, this.filterType])
      .pipe(map(it => it[0]
          .filter(item => item.isDisplayed(it[1]))
          .sort(TabHomePageComponent.getSortComparator(it[1]))
        )
      );

    this.countPerformed = this.bookings.pipe(
      map(it => it.filter(items => items.booking.status === BookingStatus.PERFORMED).length),
    );

    this.countWaiting = this.bookings.pipe(
      map(it => it.filter(items => items.booking.status === BookingStatus.WAITING).length),
    );

    this.settingsService.observableBookingActive
      .pipe(filter(it => !it))
      .subscribe(async (_: any) => await this.showAlertOnBookingDisabled());
  }

  public displayedBookings: Observable<BookingView[]>;
  public countPerformed: Observable<number>;
  public countWaiting: Observable<number>;
  public myUser: Observable<User>;
  private bookings: Observable<BookingView[]>;
  private filterType = new BehaviorSubject<FilterType>(FilterType.WAITING);

  private static getSortComparator(type: FilterType): (r: BookingView, l: BookingView) => number {
    switch (type) {
      // 作成時刻の古いものが上になる
      case FilterType.ALL:
      case FilterType.WAITING:
        return (r, l) => Booking.compareCreatedAt(r.booking, l.booking);
      // 演奏時刻の古いものが上になる
      case FilterType.PERFORMED:
        return (r, l) => Booking.comparePerformedAt(r.booking, l.booking);
    }
  }

  public onFilterChange(event: CustomEvent<{ value: FilterType }>) {
    this.filterType.next(event.detail.value);
  }

  private async showAlertOnBookingDisabled() {
    const alert = await this.alertController.create({
      header: '予約の受付期間外です',
      message: '予約曲をキャンセルした場合予約システムからは再登録できません。曲を変更したい場合は、管理者までお問い合わせください。',
      buttons: [{
        text: 'OK'
      }]
    });
    await alert.present();
  }
}
