<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>参加者管理</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list *ngIf="users | async as $users">
    <ion-item button *ngFor="let user of $users" [routerLink]="user.id" routerDirection="forward">
      <ion-icon [name]="user.roleIcon" slot="start"></ion-icon>
      <ion-label>{{user.displayName}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>