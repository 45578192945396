import {Component, OnInit} from '@angular/core';

import {LoadingController, Platform} from '@ionic/angular';
import {SettingsService} from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform,
    private settingsService: SettingsService,
    private loadingController: LoadingController,
  ) {
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: '読み込み中'
    });
    await loading.present();
    await this.platform.ready();

    // true または false の値が帰ってくるまで待つ
    const _ = await this.settingsService.fetch();
    await loading.dismiss();
  }
}
