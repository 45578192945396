import { Component } from '@angular/core';
import {MenuController} from '@ionic/angular';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.page.html',
  styleUrls: ['./admin-root.page.scss'],
})
export class AdminRootPageComponent {

  constructor(private menuController: MenuController) {
    // this.menuController.enable(true, 'first');
  }

}
