import {EntityWithMeta, UserEntity} from '../firestore/entity';
import {AuthProviderType, RoleType} from '../common';
import { DocumentReference } from 'firebase/firestore';

export default class User {
  constructor(private data: EntityWithMeta<UserEntity>) {
  }

  get id(): string {
    return this.data.id;
  }

  get entity(): UserEntity {
    return this.data.entity;
  }

  get ref(): DocumentReference<UserEntity> {
    return this.data.ref;
  }

  get displayName(): string {
    return this.data.entity.displayName;
  }

  get isAdmin(): boolean {
    return this.data.entity.role === RoleType.Admin;
  }

  get isGuest(): boolean {
    return this.data.entity.method === AuthProviderType.Guest;
  }

  get roleIcon(): string {
    switch (this.data.entity.role) {
      case RoleType.Admin:
        return 'star';
      case RoleType.User:
        return this.isGuest ? 'person-outline' : 'person';
    }
  }

  get authTypeName(): string {
    return this.entity.method;
  }

  get role(): RoleType {
    return this.data.entity.role;
  }

  set role(name: RoleType) {
    this.data.entity.role = name;
  }

  /**
   * 通常ユーザーかゲストかを返します
   */
  get type(): string {
    return 'user';
  }
}
