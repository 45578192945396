import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TabUserPageComponent } from './tab-user.page';
import {ListItemSongComponentModule} from '../../../components/public/list-item-song/list-item-song.module';
import {TabUserPageRoutingModule} from './tab-user-routing.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule.forChild([{path: '', component: TabUserPageComponent}]),
        ListItemSongComponentModule,
        TabUserPageRoutingModule
    ],
  declarations: [TabUserPageComponent]
})
export class TabUserPageModule {}
