import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { UserService } from "../services/user.service";
import { map, take } from "rxjs/operators";
import { SettingsService } from "../services/settings.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MaintenanceGuard  {
  constructor(
    private userService: UserService,
    private settingsService: SettingsService,
    private router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    // value にまだ null が入っていることがあるので、最新の値を取得する必要がある
    const isEventActive = await this.settingsService
      .fetch()
      .then((it) => it.isEventActive);
    if (isEventActive) {
      // イベント開催中
      return true;
    } else {
      // メンテナンス中
      const isActiveSource = this.userService
        .myUser({ ignoreOnNotLoggedIn: false })
        .pipe(map((it) => it.isAdmin));
      const isActive = await firstValueFrom(isActiveSource).catch((_) => false);
      if (!isActive) {
        await this.router.navigate(["/maintenance"]);
      }
      return isActive;
    }
  }
}
