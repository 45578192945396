import {Component, Input} from '@angular/core';
import {emphasizeImportantName} from '../../../common';
import Song from '../../../models/song';
import User from '../../../models/user';

@Component({
  selector: 'app-list-item-song',
  templateUrl: './list-item-song.component.html',
  styleUrls: ['./list-item-song.component.scss'],
})
export class ListItemSongComponent  {

  @Input() public song?: Song;
  @Input() public user?: User;
  @Input() public lineType?: string;
  @Input() public detail = true;
  @Input() public color?: string;
  @Input() public isButton = true;
  @Input() public order?: string;

  constructor() {
  }

  get titleKpDescription(): string {
    return emphasizeImportantName(this.song?.titleKp || '');
  }

  get titleJpDescription(): string {
    return emphasizeImportantName(this.song?.titleJp || '');
  }

}
