<ion-header>
  <ion-toolbar color="primary">
    <ion-title>電話番号でログイン</ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list lines="full">
    <ion-list-header>1. 本人確認</ion-list-header>
    <ion-item>
      <div id="recaptcha"></div>
    </ion-item>
  </ion-list>
  <form>
    <ion-list lines="full">
      <ion-list-header>2. 確認コードの送信</ion-list-header>
      <ion-item>
        <ion-input
            name="phoneNumber"
            placeholder="電話番号を入力してください"
            [ngModel]="(phoneNumber | async) || ''"
            (ngModelChange)="phoneNumber.next($event)">
        </ion-input>
      </ion-item>
    <div class="submit">
      <ion-button type="button" (click)="onSendCodeClick()" [disabled]="isDisabledSendSms | async">確認コードを送信する</ion-button>
    </div>
    </ion-list>
    <div>
      <ion-text [color]="textColorSendCode">{{messageSendCode}}</ion-text>
    </div>
  </form>
  <form>
    <ion-list lines="full">
      <ion-list-header>3. 確認コードの入力</ion-list-header>
      <ion-item>
        <ion-input
          name="confirmCode"
          placeholder="確認コードを入力してください"
          [(ngModel)]="confirmCode">
        </ion-input>
      </ion-item>
      <div class="submit">
        <ion-button type="button" (click)="onLoginClick()" [disabled]="(canClickLogin | async) === false">ログイン</ion-button>
      </div>
    </ion-list>
  </form>
</ion-content>
