<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      私の予約履歴
    </ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="myBookings | async as $bookings; else loading">
    <ng-container *ngIf="$bookings.length > 0; else empty">
      <app-list-booking-history [bookings]="$bookings"></app-list-booking-history>
    </ng-container>
  </ng-container>
</ion-content>

<ng-template #loading>
  <ion-spinner></ion-spinner>
</ng-template>

<ng-template #empty>
  <app-center-notice text="予約履歴がありません"></app-center-notice>
</ng-template>
