<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{title || "読み込み中..."}}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!isEmpty; else empty">
  <app-list-item-song
      *ngFor="let song of songs"
      [isButton]="isBookingEnabled | async"
      [detail]="false"
      [song]="song"
      (click)="onItemClick(song)">
  </app-list-item-song>
</ion-content>
<ng-template #empty>
  <ion-content>
    <app-center-notice></app-center-notice>
  </ion-content>
</ng-template>
