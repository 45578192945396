import {Component, Input} from '@angular/core';
import {emphasizeImportantName} from '../../../common';

@Component({
  selector: 'app-list-item-category',
  templateUrl: './list-item-category.component.html',
  styleUrls: ['./list-item-category.component.scss'],
})
export class ListItemCategoryComponent  {

  @Input() public kp = '';
  @Input() public jp = '';
  @Input() public route = '';

  get kpDescription(): string {
    return emphasizeImportantName(this.kp);
  }

  get jpDescription(): string {
    return emphasizeImportantName(this.jp);
  }

}
