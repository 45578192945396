import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {AdminRootPageRoutingModule} from './admin-root-routing.module';

import {AdminRootPageComponent} from './admin-root.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminRootPageRoutingModule
  ],
  exports: [],
  declarations: [AdminRootPageComponent]
})
export class AdminRootPageModule {
}
