import {Injectable} from '@angular/core';
import { CollectionReference, DocumentReference, getDoc, setDoc } from 'firebase/firestore';
import { collection, collectionSnapshots, doc, docSnapshots, Firestore, updateDoc } from '@angular/fire/firestore';
import User from '../models/user';
import {toEntityAngularFirestore, toEntityQuery, UserEntity} from '../firestore/entity';
import {flatMap, map, shareReplay} from 'rxjs/operators';
import Path from '../firestore/path';
import { Auth, signInWithPhoneNumber, signInWithRedirect, signOut, user } from '@angular/fire/auth';
import {AuthProviderType, RoleType} from '../common';
import {EMPTY, Observable} from 'rxjs';
import * as Sentry from '@sentry/angular';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {
  AuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GoogleAuthProvider,
  PhoneAuthProvider,
  ApplicationVerifier,
  ConfirmationResult,
  UserCredential,
} from 'firebase/auth';

export interface GetMyUserOptions {
  ignoreOnNotLoggedIn: boolean;
}

const defaultGetMyUserOptions: GetMyUserOptions = {ignoreOnNotLoggedIn: true};

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private platform: Platform,
    private router: Router
  ) {
  }

  // ユーザーデータのキャッシュ
  private sharedUsers: Map<string, Observable<User>> = new Map<string, Observable<User>>();

  private createProvider(method: AuthProviderType): AuthProvider {
    switch (method) {
      case AuthProviderType.Facebook:
        return new FacebookAuthProvider();
      case AuthProviderType.Twitter:
        return new TwitterAuthProvider();
      case AuthProviderType.Google:
        return new GoogleAuthProvider();
      case AuthProviderType.PhoneNumber:
        return new PhoneAuthProvider(this.auth);
      default:
        throw new Error('Invalid login method.');
    }
  }

  private getProviderType(providerId: string | null) {
    switch (providerId) {
      case FacebookAuthProvider.PROVIDER_ID:
        return AuthProviderType.Facebook;
      case TwitterAuthProvider.PROVIDER_ID:
        return AuthProviderType.Twitter;
      case GoogleAuthProvider.PROVIDER_ID:
        return AuthProviderType.Google;
      case PhoneAuthProvider.PROVIDER_ID:
        return AuthProviderType.PhoneNumber;
      default:
        throw new Error(`Unimplemented provider: ${providerId}`);
    }
  }

  private sharedUser(userId: string): Observable<User> {
    if (!this.sharedUsers.has(userId)) {
      const docRef = doc(this.firestore, Path.users, userId) as DocumentReference<UserEntity>;
      this.sharedUsers.set(
        userId,
        docSnapshots(docRef)
          .pipe(
            map(it => new User(toEntityAngularFirestore(it))),
            shareReplay(1)
          )
      );
    }
    return this.sharedUsers.get(userId) || EMPTY;
  }

  /**
   * 自身のユーザー情報を取得します
   * @throws ユーザー情報が取得できない状態になるとエラーがスローされます
   */
  myUser(options: GetMyUserOptions = defaultGetMyUserOptions): Observable<User> {
    return user(this.auth)
      .pipe(
        flatMap(it => {
          if (it == null) {
            if (options.ignoreOnNotLoggedIn) {
              return EMPTY;
            } else {
              throw new Error('Not logged in');
            }
          } else {
            return this.sharedUser(it.uid);
          }
        })
      );
  }

  /**
   * ユーザー情報を更新します
   * @param userId 更新対象のユーザー ID
   * @param entity 更新する内容
   */
  async updateUser(userId: string, entity: Partial<UserEntity>): Promise<void> {
    // entity.uid と userId が異なる場合は userId を優先する
    const entityToUpdate: Partial<UserEntity> = Object.assign({}, entity, {uid: userId});
    const docRef = doc(this.firestore, Path.users, userId);
    await updateDoc(docRef, entityToUpdate);
  }

  /**
   * ログイン成功時の処理を記述します。ポップアップでログインした場合は自動的に呼び出されます。
   * リダイレクトからの戻りの場合は、必要に応じて呼び出す必要があります。
   */
  async onLoginSuccess(userCredential: UserCredential): Promise<void> {
    // 電話番号認証の場合は credential に値が入っていないので user だけで認証済みかを判別する
    if (!user) {
      throw new Error('User not logged in.');
    }
    const {uid, email, displayName, photoURL} = userCredential.user;
    // Sentry にユーザー追加
    Sentry.setUser({
      id: uid,
      email: email || '',
      username: displayName || '',
    });

    const myUser = doc(this.firestore, Path.users, uid) as DocumentReference<UserEntity>;
    const myDocument = await getDoc(myUser);
    if (!myDocument.exists()) {
      const newUser: UserEntity = {
        uid,
        displayName: displayName || '',
        photoURL: photoURL || null,
        note: '',
        role: RoleType.User,
        method: this.getProviderType(userCredential.providerId)
      };
      await setDoc(myUser, newUser);
    }
  }

  /**
   * 予約システムにログインします。
   * @param method 予約方法
   */
  public async loginWithSocial(method: AuthProviderType): Promise<void> {
    const provider = this.createProvider(method);

    switch (method) {
      case AuthProviderType.Twitter:
      case AuthProviderType.Facebook:
      case AuthProviderType.Google:
        return signInWithRedirect(this.auth, provider);

      default:
        throw new Error(`Not supported: ${method}`);
    }
  }

  public async loginWithPhoneNumber(phoneNumber: string, verifier: ApplicationVerifier): Promise<ConfirmationResult> {
    return await signInWithPhoneNumber(this.auth, phoneNumber, verifier);
  }

  public async confirmPhoneNumber(result: ConfirmationResult, authCode: string): Promise<void> {
    const credential = await result.confirm(authCode);
    await this.onLoginSuccess(credential);
  }

  public async logout() {
    await signOut(this.auth);
    await this.router.navigate(['/login']);

    // DB から情報を再読み込みさせるため強制リロード
    // リロードせずに再ログインすると、以前のユーザー情報が残ってしまう
    location.reload();
  }

  /**
   * すべてのユーザーを取得します
   */
  public getAllUsers(): Observable<User[]> {
    const docRef = collection(this.firestore, Path.users) as CollectionReference<UserEntity>;
    return collectionSnapshots(docRef)
      .pipe(
        map(it => it.map(item => new User(toEntityQuery(item))))
      );
  }

  /**
   * ユーザー ID を指定して、ユーザーを取得します
   * @param userId ユーザー ID
   */
  public getUser(userId: string): Observable<User> {
    return this.sharedUser(userId);
  }

  /**
   * 新規ユーザーを追加します
   */
  public async addUser(userEntity: UserEntity): Promise<void> {
    const docRef = doc(this.firestore, Path.users, userEntity.uid);
    await setDoc(docRef, userEntity);
  }

  isMine(userEntity: User): Observable<boolean> {
    return user(this.auth).pipe(
      map(it => it?.uid === userEntity.id),
      shareReplay()
    );
  }
}
