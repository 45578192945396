import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AdminAttendeePageRoutingModule } from './admin-attendee-routing.module';

import { AdminAttendeePageComponent } from './admin-attendee.page';
import {AdminUserDetailPageModule} from '../admin-user-detail/admin-user-detail.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AdminAttendeePageRoutingModule,
    AdminUserDetailPageModule,
  ],
  declarations: [AdminAttendeePageComponent]
})
export class AdminAttendeePageModule {}
