import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {take} from 'rxjs/operators';
import {Location} from '@angular/common';
import {LoadingController} from '@ionic/angular';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.page.html',
  styleUrls: ['./edit-profile.page.scss'],
})
export class EditProfilePageComponent implements OnInit {

  private userId!: string;
  @Input() public displayName!: string;
  @Input() public note!: string;

  constructor(
    private userService: UserService,
    private location: Location,
    private loadingController: LoadingController
  ) {
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({message: '読み込み中'});
    await loading.present();

    const user = await firstValueFrom(this.userService.myUser());
    this.userId = user.id;
    this.displayName = user.entity.displayName;
    this.note = user.entity.note || '';

    await loading.dismiss();
  }

  public async modify(_: MouseEvent) {
    const loading = await this.loadingController.create({message: '更新中'});
    await loading.present();

    await this.userService
      .updateUser(this.userId, {displayName: this.displayName, note: this.note});
    await loading.dismiss();

    this.location.back();
  }

  get isValid(): boolean {
    return !!this.displayName;
  }

}
