import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListCategoriesPageComponent } from './list-categories.page';

const routes: Routes = [
  {
    path: 'categories/:categoryId',
    loadChildren: () =>
      import('../list-songs/list-songs.module').then(m => m.ListSongsPageModule)
  },
  {
    path: '',
    component: ListCategoriesPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListCategoriesPageRoutingModule {}
