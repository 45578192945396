<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      予約履歴
    </ion-title>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="(bookings | async)?.length > 0; else empty">
    <app-list-booking-history [bookings]="bookings | async"  [myUser]="myUser | async"></app-list-booking-history>
  </div>
</ion-content>

<ng-template #empty>
  <app-center-notice *ngIf="(isLoading | async) === false"></app-center-notice>
</ng-template>
