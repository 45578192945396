import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import Booking, {BookingStatus, BookingView} from '../../../models/booking';
import {BookingService} from '../../../services/booking.service';
import {ActionSheetController, LoadingController, ToastController} from '@ionic/angular';
import {flatMap, map, shareReplay} from 'rxjs/operators';
import {combineLatestAllowEmpty, createStandardToast, FilterType} from '../../../common';

@Component({
  selector: 'app-admin-bookings',
  templateUrl: './admin-bookings.page.html',
  styleUrls: ['./admin-bookings.page.scss'],
})
export class AdminBookingsPageComponent implements OnInit {

  public bookings: Observable<BookingView[]>;
  public displayedBookings: Observable<BookingView[]>;
  public isLoading = new BehaviorSubject<boolean>(true);
  public loading: HTMLIonLoadingElement | null = null;
  private filterType = new BehaviorSubject<FilterType>(FilterType.ALL);

  constructor(
    private bookingService: BookingService,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private toastController: ToastController
  ) {
    this.bookings = this.bookingService.getBookingList({
      containsCancelled: true
    })
      .pipe(
        flatMap(it => combineLatestAllowEmpty(it.map(item => BookingView.from(item)))),
        shareReplay()
      );

    this.displayedBookings = combineLatest([this.bookings, this.filterType])
      .pipe(map(it => it[0]
        .filter(item => item.isDisplayed(it[1]))
        .sort(AdminBookingsPageComponent.getSortComparator(it[1]))
      ));

    this.bookings.subscribe(_ => this.isLoading.next(false));
  }

  private static getSortComparator(type: FilterType): (r: BookingView, l: BookingView) => number {
    switch (type) {
      // 作成時刻の古いものが上になる
      case FilterType.ALL:
      case FilterType.WAITING:
        return (r, l) => Booking.compareCreatedAt(r.booking, l.booking);
      // 演奏時刻の古いものが上になる
      case FilterType.PERFORMED:
        return (r, l) => Booking.comparePerformedAt(r.booking, l.booking);
    }
  }

  public async ngOnInit(): Promise<void> {
    this.loading = await this.loadingController.create({message: '読み込み中'});
    this.isLoading.subscribe(async it => {
      if (it) {
        await this.loading?.present();
      } else {
        await this.loading?.dismiss();
      }
    });
  }

  public async onItemClick(booking: BookingView) {
    const sheet = await this.actionSheetController.create({
      header: `${booking.song.titleKp} (${booking.user?.displayName || '不明なメンバー'} さんが予約)`,
      buttons: [
        {
          text: '演奏済みにする',
          handler: async () => {
            return await this.changeStatus(booking.booking, BookingStatus.PERFORMED);
          }
        },
        {
          text: '未演奏状態に戻す',
          handler: async () => {
            return await this.changeStatus(booking.booking, BookingStatus.WAITING);
          }
        },
        {
          text: 'キャンセルする',
          handler: async () => {
            return await this.changeStatus(booking.booking, BookingStatus.CANCELLED);
          }
        },
      ]
    });

    sheet.onDidDismiss().then(async it => {
      if (it.role !== 'backdrop') {
        const toast = await createStandardToast(this.toastController, 'ステータスを変更しました');
        await toast.present();
      }
    });

    await sheet.present();
  }

  onFilterChange(event: CustomEvent<{ value: FilterType }>) {
    this.filterType.next(event.detail.value);
  }

  private async changeStatus(booking: Booking, status: BookingStatus): Promise<boolean> {
    await this.bookingService.updateStatus(booking, status);
    return true;
  }
}
