<ion-header>
  <ion-toolbar color="primary">
    <ion-title>マイページ</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="card">
    <div class="header">
      <div class="avatar">
        <ion-icon name="person-circle"></ion-icon>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="user-meta ion-text-center">
      <h3 class="user-name">{{displayName | async}}</h3>
      <h5 class="user-type">
        <ion-icon name="{{authProviderIconClass | async}}"></ion-icon>
        {{authProvider | async}} でログイン中
      </h5>
      <div class="ion-text-center user-note">
        {{note | async}}
      </div>
    </div>
  </div>

  <ion-list>
    <ion-list-header>
      <ion-label>メニュー</ion-label>
    </ion-list-header>

    <ion-item routerLink="./history" routerDirection="forward">
      <ion-label>予約履歴</ion-label>
      <ion-icon name="time" slot="start"></ion-icon>
    </ion-item>
    <ion-item routerLink="./profile" routerDirection="forward">
      <ion-label>プロフィール編集</ion-label>
      <ion-icon name="person" slot="start"></ion-icon>
    </ion-item>
    <ion-item (click)="logout()" button="true">
      <ion-label>ログアウト</ion-label>
      <ion-icon name="log-out" slot="start"></ion-icon>
    </ion-item>
    <ion-item routerLink="/admin" *ngIf="isAdmin | async">
      <ion-label>管理者用ページ</ion-label>
      <ion-icon name="settings" slot="start"></ion-icon>
    </ion-item>

    <ion-list-header>
      <ion-label>その他</ion-label>
    </ion-list-header>

    <ion-item>
      <ion-label>バージョン</ion-label>
      <ion-note slot="end">{{version}}</ion-note>
    </ion-item>

    <ion-item href="https://dprk-karaoke.club/privacy">
      <ion-label>プライバシーポリシー</ion-label>
    </ion-item>
  </ion-list>

</ion-content>
