<ion-content class="ion-padding">
  <div class="container-main" *ngIf="{value: isEventActive | async} as $isEventActive; else loading">
    <div *ngIf="$isEventActive.value; else maintenance" class="ion-text-center">
      <h1>ログイン</h1>
      <div>
        電話番号または SNS アカウントでログインしてください
      </div>
      <div class="alart">
        SNS プロバイダーの都合により、 Twitter, Facebook でのログイン機能の提供を一時中断しています。
        Google または電話番号での認証をご利用ください。
      </div>
    </div>
    <div class="container-ui">
      <ion-button [disabled]="true" color="twitter" (click)="onLoginClick(method.Twitter)">
        <ion-icon slot="start" name="logo-twitter"></ion-icon>
        Twitter
      </ion-button>
      <ion-button [disabled]="true" color="facebook" (click)="onLoginClick(method.Facebook)">
        <ion-icon slot="start" name="logo-facebook"></ion-icon>
        Facebook
      </ion-button>
      <ion-button color="google" (click)="onLoginClick(method.Google)">
        <ion-icon slot="start" name="logo-google"></ion-icon>
        Google
      </ion-button>
      <ion-button color="light" (click)="onLoginClick(method.PhoneNumber)">
        <ion-icon slot="start" name="call"></ion-icon>
        電話番号
      </ion-button>
    </div>
    <div class="copyright">朝鮮カラオケ大会予約システム {{version}}</div>
    <div class="privacy">
      <a href="https://dprk-karaoke.club/privacy">プライバシーポリシー</a>
    </div>
  </div>
</ion-content>

<ng-template #loading>
  <div class="container-main">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

<ng-template #maintenance>
  <h1>イベント実施期間外です</h1>
  <div>期間外は管理者のみログインできます</div>
</ng-template>
