import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AdminAttendeePageComponent} from './admin-attendee.page';
import {AdminAlternativeBookingPageModule} from '../admin-alternative-booking/admin-alternative-booking.module';
import {AdminUserDetailPageModule} from '../admin-user-detail/admin-user-detail.module';

const routes: Routes = [
  {
    path: '',
    component: AdminAttendeePageComponent
  },
  {
    path: ':userId',
    loadChildren: () => import('../admin-user-detail/admin-user-detail.module').then(m => AdminUserDetailPageModule)
  },
  {
    path: ':userId/alternative',
    loadChildren: () => import('../admin-alternative-booking/admin-alternative-booking.module').then(m => AdminAlternativeBookingPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminAttendeePageRoutingModule {
}
