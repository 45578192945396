<ion-content class="ion-padding">
  <div class="container-main">
    <h1 class="ion-text-center">メンテナンス中</h1>
    <p class="ion-text-center">管理者のみログインできます。</p>
    <p class="ion-text-center ion-color-medium">
      <ion-text color="medium">
        メンテナンスが解除されてもこのページは自動更新されません。画面をリロードするか、一度ログアウトしてやり直してください。
      </ion-text>
    </p>
    <p class="ion-text-center">
      <ion-button (click)="logout()">ログアウト</ion-button>
    </p>
  </div>
</ion-content>
